// client overrides

// framework overrides

img {
	height: auto !important;
}

@media #{$large-up} {
	.large-block-grid-2 > li {
		max-width: 373px;
	}
}

.fluidbox__overlay,
.fluidbox--opened .fluidbox__ghost,
.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close,
.mfp-zoom {
	cursor: zoom-out;
}

// additional customization

.full-width-wrapper .general-content {
	padding-left: 0.9375rem;
	padding-right: 0.9375rem;
}

.btn {
	@extend .button;
}

.sidebar a.button, button{
	display: block;
}

.arc-content-wrapper a, 
.tweetbox a,
.campaign-content-block a:not(.button),
.campaign-content-block ul li:before {
	text-decoration: underline;
}

.large-8 .twolists .list-block ul {
	column-count: 1;
}

.person-detail-block .row {
	margin: 0 -0.9375rem;
}

.callout-slab figcaption {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}

.inner-wrap > .campaign-two-block-slab {
	padding: 1rem 0 6.25rem; 
	~ .campaign-two-block-slab {
  		padding: 6.25rem 0;
	}
}

/* News landing search */
form.search.search-archives fieldset legend {
	display: none;
}

/* Staff Directory Pagination */
div.pagination ul li {
	display: inline;
	margin: 0;
	padding: 0;
	&::before {
		content: "";
	}
}

/* Staff Directory Results */
table.results tr {
	th, td {
		padding: 0.25rem 0.15rem 0.25rem 0.15rem;
	}
}
.intranet-homepage .news-list.intranet {
	padding: 0;
	background: none;
}

table {
	border-style: solid;
	border-color: #AAA;
	border-width: 1px;
}

table.border {
	th, td {
		border-style: solid;
		border-color: #AAA;
		border-width: 1px;
	}
}

th, td {
	&.bluecell {
		text-align: center; 
		color: #fff; 
		background-color: #1c4188;
	}
}

.directory-filters {
	padding-top:2rem;
	.alert {
		margin-bottom:1rem;
		padding-left:1rem !important;
	}
	select {
		margin-top:0px;
	}
}

/* Faculty Staff Listing */
.facultystaffphoto {
	padding: 0px 20px 20px 0px;
}

/* Call-out Slab */

.image-background {
	bottom: 0;
	content: "";
	display: block;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 0;
}

.callout-slab {
	color: #fff;
	margin-bottom: 1.25rem;
	overflow: hidden;
	padding: 5rem 0;
	position: relative;
	p, h1, h2, h3, h4, h5, h6, blockquote cite {
		color: #fff;
	}
	h1, h2, h3, h4, h5, h6 {
		&.header {
			font-size: 1.5rem;
			font-weight: bold;
			text-transform: uppercase;
		}
	}
	a:not(.button) {
		color: #fff;

		&:hover, &:focus {
			text-decoration: underline;
		}
		&::before, &::after {
			text-decoration: none;
		}
	}
	ul li::before {
		color: #fff;
	}
	nav.primary-nav a:hover {
		text-decoration: none;
	}
	.image-background::after {
		background-color: rgba(0, 33, 71, 0.9);
		bottom: 0;
		content: "";
		display: block;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}
	.row {
		position: relative;
	}
}
.mix-blend-mode {
	.callout-slab .image-background {
		> img {
			opacity: 0.45;
		}
		&::after {
			background-color: #002147;
			mix-blend-mode: multiply;
		}
	}
}

.image-background > img {
	max-width: none;
	width: 100%;
}

.form-description {
	float: left;
	margin-bottom: 0;
	margin-right: .25em;
}

@media #{$small-only} {
	.callout-slab {
		padding: 1.875rem 0;
		figure {
			margin-bottom: 1.25rem;
		}
	}
}
@media #{$medium-up} {
	.facultystaffphoto {
		float: left;
	}
	.arc-content-wrapper > h2 {
		margin-bottom: 2rem !important;
	}
}