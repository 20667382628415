/* -------------------------------------------------------------- */
/*    Molecules - Layout
/* -------------------------------------------------------------- */


/* Spacing Helpers
/* _______________________________________________________________ */
.space-above {
	margin-top: rem-calc(20);
}

.space-above-large {
	margin-top: rem-calc(40);
}

.space-below {
	margin-bottom: rem-calc(20);
}

.space-below-large {
	margin-bottom: rem-calc(40);
}

.flatten-bottom {
	margin-bottom: 0;
}



/* Sticky Nav
/* sticks to the top of the page as the user scrolls
/* .longwood-fixed is added automatically when the fix condition is met in js
/* using .longwood-fixed instead of .fixed to avoid conflict w/foundation sticky, which sucks.
/* _______________________________________________________________ */
.sticky-wrapper.longwood-fixed {
	display: none;
	position: fixed;
	top: 0;
	z-index: 99999999;
	// width: auto;
	width: 100%;
}


/* Wave Body Backgrounds
/* _______________________________________________________________ */
body.wave {
	$background-left-offset: -1000px;
	background-image: image-url('wave-body-bg.svg');
	background-position: $background-left-offset -1000px;
	&.wave-hero {
		background-repeat: no-repeat;
		background-position: $background-left-offset -375px;
	}
	&.wave-home {
		background-repeat: no-repeat;
		background-position: $background-left-offset 150px;
	}
}

svg:not(:root) { overflow: hidden; }