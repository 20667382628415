/* -------------------------------------------------------------- */
/*    Variables
/* -------------------------------------------------------------- */



/* Colors
/* _______________________________________________________________ */

$color-undefined: #e31ace;

// Brand Color Palette: primaries
$color-primary: #002147;
$color-secondary: #a2a4a3;
$color-tertiary: $color-undefined;
$color-quaternary: $color-undefined;
$color-quinary: $color-undefined;
$color-senary: $color-undefined;
$color-septenary: $color-undefined;
$color-octonary: $color-undefined;
$color-nonary: $color-undefined;
$color-denary: $color-undefined;

// Brand Color Palette: accents
$color-accent-primary: #1c4188;
$color-accent-secondary: #007597;
$color-accent-tertiary: #65c8c6;
$color-accent-quaternary: #f17520;
$color-accent-quinary: #ffb612;
$color-accent-senary: #bbcdf4;
$color-accent-septenary: $color-undefined;
$color-accent-octonary: $color-undefined;
$color-accent-nonary: $color-undefined;
$color-accent-denary: $color-undefined;

// Grays / Shades / Support Colors
$color-shade-lightest: #fafafa;
$color-shade-lighter: #f3f3f3;
$color-shade-light: #dedede;
$color-shade: #c2c2c2;
$color-shade-dark: #a1a1a1;
$color-shade-darker: #868686;
$color-shade-darkest: #2d2d2d;


// Utility Colors
$color-text: $color-shade-darkest;
$color-bg: $color-shade-lightest;
$color-border: $color-undefined;
$color-link: $color-accent-primary;
$color-link-hover: lighten($color-accent-primary, 10%);

// Story Label Colors
$story-labels: feature $color-accent-quinary, news $color-primary, op-ed $color-accent-quaternary, profile $color-accent-tertiary, buzz $color-accent-primary, multimedia $color-accent-secondary, essay $color-shade;


/* Typography
/* _______________________________________________________________ */

// Setup font families
$font-primary : "Gotham SSm A", "Gotham SSm B", 'Arial', sans-serif;
$font-secondary : 'Melbourne-Regular', sans-serif;
$font-tertiary: "adobe-garamond-pro", 'Georgia', serif;

// Set font variables, if any
$font-accent: $font-tertiary;

// Font sizing
$base-font-size: 16px;

$font-size-smallest: rem-calc(10.4);
$font-size-smaller: rem-calc(12);
$font-size-small: rem-calc(14);
$font-size-default: rem-calc(16);
$font-size-large: rem-calc(20);
$font-size-larger: rem-calc(24);
$font-size-largest: rem-calc(32);


/* Paths
/* _______________________________________________________________ */
$image-url-path: "/media/site-assets/public-site/images/";
$fa-font-path: "//netdna.bootstrapcdn.com/font-awesome/4.3.0/fonts"; // for referencing Bootstrap CDN font files directly


/* Layout
/* _______________________________________________________________ */
$row-width: rem-calc(1164);
$max-width: $row-width;

$negative-bleed: -9999px;


/* Spacing Helpers
/* _______________________________________________________________ */
$space : 1em;
$space-and-half : $space*1.5;
$space-double : $space*2;
$space-quad : $space*4;
$space-half : $space/2;
$pad : 1em;
$pad-and-half : $pad*1.5;
$pad-double : $pad*2;
$pad-half : $pad/2;
$pad-quarter :$pad/4;


/* Animations
/* _______________________________________________________________ */
$transition-time: .3s;
$transition-time-slow: .8s;


/* Media Queries
/* _______________________________________________________________ */
$small-breakpoint:  em-calc(640)  !default;
$medium-breakpoint: em-calc(1020) !default;
$large-breakpoint:  em-calc(1440) !default;
$xlarge-breakpoint: em-calc(1920) !default;

$small-range:   (0, $small-breakpoint) !default;
$medium-range:  ($small-breakpoint  + em-calc(1), $medium-breakpoint) !default;
$large-range:   ($medium-breakpoint + em-calc(1), $large-breakpoint)  !default;
$xlarge-range:  ($large-breakpoint  + em-calc(1), $xlarge-breakpoint) !default;
$xxlarge-range: ($xlarge-breakpoint + em-calc(1), em-calc(99999999)) !default;

$xlarge-down: "(max-width:#{upper-bound($xlarge-range)})";
$large-down: "(max-width:#{upper-bound($large-range)})";
$medium-down: "(max-width:#{upper-bound($medium-range)})";
$small-down: "(max-width:#{upper-bound($small-range)})";

// foundation also provides $size-up and $size-only mq's.