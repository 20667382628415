/* -------------------------------------------------------------- */
/*    Molecules - Page Headers
/* -------------------------------------------------------------- */


/* Page Header Default
/* _______________________________________________________________ */
.page-header {
	padding: rem-calc(30) 0;
	@media #{$medium-down} {
		padding: rem-calc(10) 0;
	}
	h1, h2, h3, h4, h5, h6 {
		color: $color-primary;
		font-size: rem-calc(70);
		line-height: rem-calc(70);
		font-family: $font-secondary;
		margin: 0;
		@media #{$medium-down} {
			font-size: rem-calc(50);
		}
		@media #{$small-down} {
			font-size: $font-size-largest;
			line-height: 1.4;
		}
	}
	& > .row {
		display: flex;
		align-items: flex-end;
		@media #{$medium-down} {
			display: inline-block;
		}
		& > .columns {
			display: flex;
			@media #{$medium-down} {
				display: inline-block;
			}
		}
	}
	.page-header-sub {
		margin-top: 1rem;
		p,
		a {
			margin-bottom: 0;
			font-size: $font-size-small;
			color: $color-primary;
		}
		a {
			@extend .arrow-link;
			&:hover {
				color: $color-link;
			}
		}
	}
	.blurb {
		color: $color-shade-darker;
	}
	.image {
		@media #{$medium-down} {
			text-align: center;
			margin-top: rem-calc(-50);
		}
		@media #{$small-down} {
			margin-top: rem-calc(20);
		}
	}
}

.sticky-wrapper.longwood-fixed {
	width: 100%;
	background-color: $color-bg;
	border-bottom: $color-shade 2px solid;
	ul.breadcrumbs {
		margin-top: rem-calc(10);
	}
	.page-header {
		padding: 0 0 rem-calc(10) 0;
		h1, h2, h3, h4, h5, h6 {
			font-size: $font-size-largest;
			line-height: 2.5rem;
			margin-top: 0.3rem;
		}
	}
}
