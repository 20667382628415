/* -------------------------------------------------------------- */
/*    Molecules - Text
/* -------------------------------------------------------------- */


/* Blockquote with Share Link
/* _______________________________________________________________ */
blockquote {
	a.tweet-link {
		margin-top: rem-calc(10);
		font-weight: bold;
	}
}