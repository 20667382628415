/* -------------------------------------------------------------- */
/*    Organisms - Campaign
/* -------------------------------------------------------------- */


/* Campaign Two Block Slab
/* _______________________________________________________________ */
.campaign-two-block-slab {
	& > .row {
		& > .columns {
			padding-left: rem-calc(15/2);
			padding-right: rem-calc(15/2);
			@media #{$medium-down} {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	.quote-block {
		$gutter-jump: rem-calc(-120);
		&.curve-left {
			margin-left: $gutter-jump;
			@media #{$medium-down} {
				margin-left: 0;
			}
		}
		&.curve-right {
			margin-right: $gutter-jump;
			@media #{$medium-down} {
				margin-right: 0;
			}
		}
	}
}


/* Campaign Content Block Wide
/* _______________________________________________________________ */
.campaign-content-block-wide {
	padding: 1rem 0;
	@media #{$small-down} {
		padding: rem-calc(40) 0;
	}
	.header {
		text-align: center;
		margin-bottom: rem-calc(40);
		@media #{$small-down} {
			margin-bottom: 0;
		}
		h1, h2, h3, h4, h5, h6 {
			color: $color-accent-secondary;
		}
	}
	ul.fact-list {
		@include block-grid(2);
	}
	figure {
		margin-bottom: rem-calc(20);
	}
}