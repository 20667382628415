/* -------------------------------------------------------------- */
/*  Atoms - Forms
/* -------------------------------------------------------------- */


/* Form Defaults
/* _______________________________________________________________ */
// move labels higher above text fields, selects, textareas, anything with a box
input[type="text"],
input[type="password"],
input[type="url"],
input[type="email"],
input[type="number"],
input[type="color"],
input[type="number"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime"],
input[type="datetime-local"],
textarea,
select {
	margin-top: rem-calc(17);
	margin-bottom: rem-calc(25);
}


/* Buttons: Submit/Reset
/* _______________________________________________________________ */
button {
	@extend a.button;
	@extend a.button-highlight;
}


/* Checkbox
/* _______________________________________________________________ */



/* HTML5 Inputs
/* _______________________________________________________________ */
input[type="color"],
input[type="range"] {
	cursor: pointer;
}

input[type="number"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime"],
input[type="datetime-local"] {
	cursor: text;
}


/* Radio Buttons
/* _______________________________________________________________ */



/* Select Menu
/* _______________________________________________________________ */
select {
	cursor: pointer;
}


/* Text Fields
/* _______________________________________________________________ */