/* -------------------------------------------------------------- */
/*    Molecules - Social
/* -------------------------------------------------------------- */

/* Social Link Icons */

.social-link-block {
	margin-top: rem-calc(24);
}

.social-link {
	font-size: 0;
	
	&::before {
		display: inline-block;
		margin-right: 1em;
		font-size: 1.5rem;
		font-family: 'FontAwesome';
	}
	
	&.facebook {
		&::before {
			content: '\f09a';
		}
	}
	
	&.twitter {
		&::before {
			content: '\f099';
		}
	}
	
	&.instagram {
		&::before {
			content: '\f16d';
		}
	}
	
	&.youtube {
		&::before {
			content: '\f167';
		}
	}
	
}


/* Article Comments
/* _______________________________________________________________ */
.article-comments {
	margin-bottom: rem-calc(20);
	h1, h2, h3, h4, h5, h6 {
		padding-left: rem-calc(55);
		font-size: $font-size-largest;
		margin-bottom: rem-calc(20);
		position: relative;
		&:before {
			position: absolute;
			display: block;
			content: '';
			background: image-url('icon-speech-dots.svg') no-repeat top center;
			background-size: contain;
			top: 2px;
			left: 0;
			width: rem-calc(42);
			height: rem-calc(42);
		}
	}
}


/* Feeds
/* _______________________________________________________________ */



/* Feed - Facebook
/* _______________________________________________________________ */
.feed.facebook {
	margin-bottom: rem-calc(20);
	blockquote {
		// 	TEMPORARY hiding while fb is sandboxed, so the widget placeholder doesn't look broken.
		//	delete this later once fb is actually running
		display: none; 
	}
}


/* Feed - Twitter
/* _______________________________________________________________ */



/* Single Tweet Promo
/* _______________________________________________________________ */
.single-tweet-promo {
	$quote-icon-size: rem-calc(40);
	$quote-icon-offset: rem-calc(10);
	h1, h2, h3, h4, h5, h6 {
		&.header {
			font-weight: bold;
			margin-bottom: rem-calc(10);
		}
	}
	.tweetbox {
		background: lighten($color-primary, 10%);
		@include on-dark();
		text-align: center;
		padding: rem-calc(20);
		margin-bottom: rem-calc(20);
		h1, h2, h3, h4, h5, h6 {
			&.tweetbox-header {
				font-weight: bold;
				position: relative;
				margin-top: $quote-icon-size + $quote-icon-offset;
				font-size: $font-size-default;
				span {
					padding-right: rem-calc(10);
					border-right: #fff 2px solid;
					margin-right: rem-calc(7);
					line-height: rem-calc(10);
					&:last-child {
						padding: 0;
						border: 0;
						margin: 0;
					}
				}
				&:before {
					position: absolute;
					display: block;
					content: '';
					background: image-url('icon-speech-dots-white.svg') no-repeat top center;
					background-size: contain;
					right: 0;
					left: 0;
					bottom: auto;
					margin: auto;
					width: rem-calc(30);
					height: $quote-icon-size;
					top: -$quote-icon-size - $quote-icon-offset;
				}
			}
		}
		a.twitter-handle {
			margin-bottom: rem-calc(10);
			display: inline-block;
		}
		p.tweet-text {
			margin-bottom: 0;
		}
	}
}