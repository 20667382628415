@import "includes/mixins";
@import "includes/variables";
@import "includes/reset";


/* Foundation */
@import '../bower_components/foundation/scss/foundation/components/grid';
@import '../bower_components/foundation/scss/foundation/components/accordion';
@import '../bower_components/foundation/scss/foundation/components/alert-boxes';
@import '../bower_components/foundation/scss/foundation/components/block-grid';
// @import '../bower_components/foundation/scss/foundation/components/breadcrumbs';
//@import '../bower_components/foundation/scss/foundation/components/button-groups';
//@import '../bower_components/foundation/scss/foundation/components/buttons';
// @import '../bower_components/foundation/scss/foundation/components/clearing';
//@import '../bower_components/foundation/scss/foundation/components/dropdown';
//@import '../bower_components/foundation/scss/foundation/components/dropdown-buttons';
//@import '../bower_components/foundation/scss/foundation/components/flex-video';
@import '../bower_components/foundation/scss/foundation/components/forms';
//@import '../bower_components/foundation/scss/foundation/components/icon-bar';
//@import '../bower_components/foundation/scss/foundation/components/joyride';
//@import '../bower_components/foundation/scss/foundation/components/keystrokes';
//@import '../bower_components/foundation/scss/foundation/components/labels';
//@import '../bower_components/foundation/scss/foundation/components/magellan';
//@import '../bower_components/foundation/scss/foundation/components/orbit';
//@import '../bower_components/foundation/scss/foundation/components/pagination';
//@import '../bower_components/foundation/scss/foundation/components/panels';
//@import '../bower_components/foundation/scss/foundation/components/pricing-tables';
//@import '../bower_components/foundation/scss/foundation/components/progress-bars';
//@import '../bower_components/foundation/scss/foundation/components/range-slider';
@import '../bower_components/foundation/scss/foundation/components/reveal';
//@import '../bower_components/foundation/scss/foundation/components/side-nav';
//@import '../bower_components/foundation/scss/foundation/components/split-buttons';
//@import '../bower_components/foundation/scss/foundation/components/sub-nav';
//@import '../bower_components/foundation/scss/foundation/components/switches';
//@import '../bower_components/foundation/scss/foundation/components/tables';
@import '../bower_components/foundation/scss/foundation/components/tabs';
//@import '../bower_components/foundation/scss/foundation/components/thumbs';
//@import '../bower_components/foundation/scss/foundation/components/tooltips';
//@import '../bower_components/foundation/scss/foundation/components/top-bar';
@import '../bower_components/foundation/scss/foundation/components/type';
@import '../bower_components/foundation/scss/foundation/components/offcanvas';
@import '../bower_components/foundation/scss/foundation/components/visibility';


/* Font awesome icons */
@import "../bower_components/font-awesome/scss/variables";
@import "../bower_components/font-awesome/scss/mixins";
@import "../bower_components/font-awesome/scss/path";
@import "../bower_components/font-awesome/scss/core";

// @import "../bower_components/font-awesome/scss/larger";
// @import "../bower_components/font-awesome/scss/fixed-width";
// @import "../bower_components/font-awesome/scss/list";
// @import "../bower_components/font-awesome/scss/bordered-pulled";
// @import "../bower_components/font-awesome/scss/animated";
@import "../bower_components/font-awesome/scss/rotated-flipped";
// @import "../bower_components/font-awesome/scss/stacked";
@import "../bower_components/font-awesome/scss/icons";


/* Slick */
@import "../bower_components/slick-carousel/slick/slick";

/* Fluidbox Lightbox */
@import "../bower_components/fluidbox/src/css/_fluidbox";

/* Responsive Tabs */
@import "includes/third-party/responsive-tabs";
//@import "includes/third-party/responsive-tabs/style.css";

/* Magnific Popup (for lightbox) */
@import "includes/third-party/magnific-popup";

/*
 * Patterlab styles
 */

@import "atoms/meta";
@import "atoms/global";
@import "atoms/text";
@import "atoms/tables";
@import "atoms/media";
@import "atoms/lists";
@import "atoms/links";
@import "atoms/images";
@import "atoms/forms";
@import "atoms/buttons";

@import "molecules/blocks";
@import "molecules/forms";
@import "molecules/layout";
@import "molecules/lists";
@import "molecules/media";
@import "molecules/messaging";
@import "molecules/navigation";
@import "molecules/news";
@import "molecules/page-headers";
@import "molecules/social";
@import "molecules/teasers";
@import "molecules/text";


@import "organisms/global";
@import "organisms/general";
@import "organisms/campaign";
@import "organisms/fact-slabs";
@import "organisms/grids";
@import "organisms/news";
@import "organisms/full-page";

// client overrides
@import "includes/overrides";

@import "includes/print";