/* -------------------------------------------------------------- */
/*    Molecules - Teasers
/* -------------------------------------------------------------- */


/* Teaser
/* _______________________________________________________________ */
.teaser {
	margin-bottom: rem-calc(20);
	&.dotted-line {
		@include dotted-lines(above-below);
	}
	&.boxed {
		background: #fff;
		padding: rem-calc(20);
		margin-top: 1rem;
	}
	&.simple-image {
		figure {
			margin-bottom: rem-calc(20);
		}
		.headline {
			font-size: $font-size-default;
			margin-bottom: 0;
		}
	}
	&.theme-dark {
		&.simple-image {
			figure {
				margin-bottom: 0;
			}
		}
		.teaser-content-wrapper {
			background-color: $color-accent-primary;
			padding: rem-calc(20) rem-calc(30) rem-calc(30) rem-calc(30);
			@include on-dark();
		}
		.button {
			@extend .button-highlight;
		}
	}
	&.big-headline {
		.headline {
			font-size: rem-calc(40);
			line-height: rem-calc(44);
			font-weight: normal;
			margin-bottom: rem-calc(10);
			@media #{$medium-down} {
				font-size: $font-size-large;
				line-height: $font-size-large + rem-calc(4);
			}
		}
		.subhead {
			font-family: $font-secondary;
			margin-bottom: rem-calc(10);
		}
	}
	.superhead {
		// font-size: $font-size-small;
		font-family: $font-secondary;
		// text-transform: uppercase;
	}
	.headline {
		font-weight: bold;
		font-size: $font-size-larger;
		margin-bottom: rem-calc(10);
	}
	.subhead {
		// font: bold 1.2em $font-secondary;
	}
	.blurb {
		// font: normal 1em $font-accent;
	}
	ul {
		margin-bottom: 0;
	}
}



/* Photo Button Teaser
/* _______________________________________________________________ */
.photo-button-teaser {
	h1, h2, h3, h4, h5, h6 {
		&.header {
			font-size: $font-size-large;
			font-weight: bold;
			@include dotted-lines(below, true);
			text-align: center;
			margin-bottom: rem-calc(20);
			&:after {
				margin-top: 0;
			}
		}
	}
	.photo {
		position: relative;
		figure {
			margin: 0 auto rem-calc(20) auto;
			a {
				display: block;
				background: #000;
				img {
					opacity: 0.85;
					@include transition(opacity, $transition-time, ease-out);
				}
			}
			figcaption.blurb {
				background: transparent;
				padding: 0;
				font-family: $font-primary;
				margin-top: rem-calc(20);
			}
		}
		.button-wrapper {
			position: absolute;
			margin: auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			text-align: center;
			a.button {
				@extend .button-arrow;
			}
		}
	}
	&.active {
		.photo {
			figure {
				a {
					img {
						opacity: 1;
					}
				}
			}
		}
		a.button {
			background-color: lighten($color-accent-primary, 15%);
		}
	}
}