/* -------------------------------------------------------------- */
/*    Atoms - Meta (sort of)
/* -------------------------------------------------------------- */
// in the atom patterns, meta defines the document head and footer
// here in the sass, I'll use this meta placeholder for some basic setup-type things



/* Body Defaults
/* _______________________________________________________________ */
body {
	background: $color-bg;
	font: 100%/1.5 $font-primary;
	-webkit-text-size-adjust: 100%;
	color: $color-text;
 }


/* Global Helper Classes
/* _______________________________________________________________ */
// note there's a clearfix mixin in the mixins file too :)
.clearfix {
	*zoom: 1;
}
.clearfix:before, .cf:after {
	content: " "; /* 1 */
	display: table; /* 2 */
}
.clearfix:after {
	clear: both;
}

/* Completely remove from the flow but leave available to screen readers. */
.show-for-screenreaders {
	position: absolute !important;
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
}


 /* Spacing Helpers
 /* _______________________________________________________________ */
 .space-above {
 	margin-top: rem-calc(20);
 }
  .space-above-large {
 	margin-top: rem-calc(40);
 }
  .space-below {
 	margin-bottom: rem-calc(20);
 }
  .space-below-large {
 	margin-bottom: rem-calc(40);
 }
 .flatten-bottom {
 	margin-bottom: 0;
 }

// .text-replace {
// 	display: inline-block;
// 	font-size: 0;
// }

// // for pseudoelement overlays
// %pseudo-cover {
// 	position: absolute;
// 	top: 0;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// 	content: '';
// 	display: block;
// }

// .background-underlay {
// 	@extend %pseudo-cover;
// 	z-index: -1;
// 	background-size: cover;
// 	background-position: 0 100%;
// 	background-repeat: no-repeat;
// }

