/* -------------------------------------------------------------- */
/*	Atoms - Links
/* -------------------------------------------------------------- */

/* Link alt color
/* _______________________________________________________________ */

a.color-alt {
    
    &:link, &:visited {
        color: $color-accent-quinary;
    }
    
    &:hover, &:focus, &:active {
        color: lighten($color-accent-quinary, 15%);
    }
}

/* Angle Link
/* _______________________________________________________________ */
a.angle-link {
	text-decoration: none;
	font-size: $font-size-small;
	font-family: $font-secondary;
	&:after {
		@extend .fa;
		content: $fa-var-angle-right;
		margin-left: rem-calc(4);
	}
}


/* Arrow Link
/* _______________________________________________________________ */
a.arrow-link {
	text-decoration: none;
	font-weight: bold;
	&:before {
		@extend .fa;
		content: $fa-var-long-arrow-right;
		margin-right: rem-calc(7);
	}
}


/* Back to Top
/* _______________________________________________________________ */
a.back-to-top {
	text-decoration: none;
	font-size: $font-size-large;
	font-weight: bold;
	display: inline-block;
	margin-bottom: rem-calc(20);
	&:hover {
		&:before {
			background-color: $color-link-hover;
		}
	}
	&:before {
		// $circle-size: rem-calc(36);
		$circle-size: 36px;
		display: inline-block;
		@extend .fa;
		content: $fa-var-angle-up;
		font-size: $font-size-smaller;
		margin-right: rem-calc(7);
		background-color: $color-link;
		transition: $transition-time;
		color: #fff;
		height: $circle-size;
		width: $circle-size;
		line-height: $circle-size;
		text-align: center;
		border-radius: $circle-size/2;
		vertical-align: middle;
	}
}


/* Tweet Link
/* _______________________________________________________________ */
a.tweet-link {
	text-transform: uppercase;
	text-decoration: none;
	&:hover {

	}
	&:before {
		@extend .fa;
		@extend .fa-flip-horizontal;
		content: $fa-var-reply;
		margin-right: rem-calc(7);
	}
}


/* Story Label
/* _______________________________________________________________ */
a.story-label {
	display: inline-block;
	padding: rem-calc(4) rem-calc(20) 0;
	font-family: $font-secondary;
	font-size: $font-size-smaller;
	text-transform: uppercase;
	text-decoration: none;
	color: #fff;
	background-color: $color-shade;
	&:hover {
		background-color: lighten($color-shade, 5%);
	}
	@each $label in $story-labels {
		$label-name: nth($label, 1);
		$label-color: nth($label, 2);
		&.label-#{$label-name} {
			background-color: #{$label-color};
				&:hover {
					background-color: lighten($label-color, 10%);
				}
		}
	}
}

