@media print {

	.print-only {
		display: block;
	}

	.hide-for-print,
	header.main-header,
	footer.main-footer,
	ul.breadcrumbs,
	.slab-carousel,
	.gallery-carousel,
	.secondary-nav,
	.social-feeds,
	.twitter,
	.facebook,
	.block-button-grid-slab,
	.bond-grid,
	.contact-block,
	.tweet-link,
	.single-tweet-promo,
	.back-to-top {
		display: none !important;
	}

	p,
	blockquote,
	blockquote p,
	blockquote cite,
	ul li,
	ol li {
		font-size: 10px !important;
	}

	h1 {
		font-size: 16px !important;
	}
	h2 {
		font-size: 15px !important;
	}
	h3 {
		font-size: 14px !important;
	}
	h4 {
		font-size: 13px !important;
	}
	h5 {
		font-size: 12px !important;
	}
	h6 {
		font-size: 11px !important;
	}

	img {
		max-width: 40%;
	}
}