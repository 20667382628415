/* -------------------------------------------------------------- */
/*    Atoms - Images
/* -------------------------------------------------------------- */


/* Favicon
/* _______________________________________________________________ */



/* Icons
/* _______________________________________________________________ */



/* Image Sizes
/* _______________________________________________________________ */



/* Logos
/* _______________________________________________________________ */
// 						future nice feature: dark bg to demo logo on
// 						since it's white :)
.logo {
	display: inline-block;
	img {
		max-width: 100%;
		width: 344px;
		height: 50px;
	}
}

