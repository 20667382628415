/* -------------------------------------------------------------- */


/*    Organisms - General
/* -------------------------------------------------------------- */


/* Callout Slab
/* _______________________________________________________________ */

.callout-slab {
    background: #002147 image-url("background-photo-texture2.png") no-repeat center center;
    background-size: cover;
    @include on-dark();
    padding: rem-calc(80) 0;
    margin-bottom: rem-calc(20);
    @media #{$small-down} {
        padding: rem-calc(30) 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &.header {
            font-size: $font-size-larger;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    figure {
        @media #{$small-down} {
            margin-bottom: rem-calc(20);
        }
    }
}


/* Filtration
/* _______________________________________________________________ */

.filtration {
    position: relative;
    .sticky-wrapper {
        @media #{$medium-down} {
            position: static;
        }
        &.longwood-fixed {
            position: fixed;
            border: 0;
            background: transparent;
            .filters-wrapper {
                a.toggle-filters {
                    border-radius: 0 0 rem-calc(16) rem-calc(16);
                }
                &.active a.toggle-filters {
                    @media #{$medium-down} {
                        border-radius: 0;
                    }
                }
            }
            ul.filters {
                border-radius: 0 0 rem-calc(16) 0;
                @media #{$medium-down} {
                    border-radius: 0 0 rem-calc(16) rem-calc(16);
                }
            }
        }
    }
    .filters-wrapper {
        margin-bottom: rem-calc(30);
        @media #{$medium-down} {
            position: static;
            margin-bottom: rem-calc(30);
        }
        a.toggle-filters {
            display: block;
            line-height: rem-calc(40);
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;
            position: relative;
            z-index: 2;
            &:focus {
                background-color: $color-accent-primary;
            }
            &:before {
                @extend .fa;
                content: $fa-var-filter;
                margin-right: rem-calc(7);
            }
            @media #{$medium-down} {
                margin: 0 rem-calc(10);
            }
        }
        ul.filters {
            display: none;
            background: $color-accent-primary;
            border-radius: 0 rem-calc(16) rem-calc(16) 0;
            padding: 0 rem-calc(20) 0 rem-calc(40);
            line-height: rem-calc(40);
            margin: 0 0 0 rem-calc(-15);
            position: relative;
            z-index: 1;
            @extend .inline;
            @media #{$medium-down} {
                margin: 0 rem-calc(10);
                border-radius: 0 0 rem-calc(16) rem-calc(16);
            }
            li {
                label {
                    color: #fff;
                    input {
                        cursor: pointer;
                        margin: 0 rem-calc(5) 0 0;
                    }
                }
            }
        }
        &.active {
            a.toggle-filters {
                background-color: $color-primary;
                @media #{$medium-down} {
                    border-radius: rem-calc(16) rem-calc(16) 0 0;
                }
                &:before {
                    content: $fa-var-close;
                }
            }
            ul.filters {
                display: block;
            }
        }
    }
    ul.fact-list {
        display: flex;
        align-items: stretch;
        margin-bottom: rem-calc(20);
        li {
            flex: 1 0 0;
            padding-right: rem-calc(20);
            @media #{$small-down} {
                padding-left: rem-calc(10);
                padding-right: rem-calc(10);
            }
            &:first-child {
                @media #{$small-down} {
                    border-left: 0;
                    padding-left: 0;
                }
            }
            &:last-child {
                padding-right: 0;
            }
            .fact {
                font-size: $font-size-largest;
                color: $color-primary;
                @media #{$medium-down} {
                    font-size: $font-size-larger;
                }
                @media #{$small-down} {
                    font-size: $font-size-large;
                }
            }
            .fact-detail {
                font-size: $font-size-small;
                @media #{$small-down} {
                    font-size: $font-size-smallest;
                }
            }
        }
    }
}


/* Hero Slab
/* _______________________________________________________________ */

.hero-slab {
    margin-bottom: rem-calc(80);
    position: relative;
    @media #{$medium-down} {
        margin-bottom: rem-calc(100);
    }
    @media #{$small-down} {
        margin-bottom: rem-calc(20);
    }
    .hero-cutout-image {
        margin-bottom: 0;
        &:after {
            @media #{$medium-down} {
                display: none;
            }
        }
    }
    .arc-column {
        @media #{$small-down} {
            padding: 0;
        }
    }
    .arc-block-wrapper {
        position: absolute;
        bottom: rem-calc(-60);
        @media #{$medium-down} {
            bottom: rem-calc(-80);
        }
        @media #{$small-down} {
            position: static;
        }
        .arc-block {
            @media #{$medium-down} {
                padding-top: rem-calc(20);
                padding-right: rem-calc(20);
                padding-bottom: rem-calc(20);
            }
            @media #{$small-down} {
                background-color: $color-accent-tertiary;
                padding: rem-calc(20);
            }
            .shape-wrapper {
                path#shape-wave {
                    display: block;
                    @media #{$small-down} {
                        display: none;
                    }
                }
                path#shape-color {
                    @media #{$small-down} {
                        display: none;
                    }
                }
            }
        }
    }
}


/* Homepage Collage
/* _______________________________________________________________ */

.homepage-collage {
    $collage-gutter: rem-calc(8);
    $collage-popout: rem-calc(175);
    $slot-height: rem-calc(481);
    margin-top: rem-calc(30);
    max-width: 94.625rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    
    flex-flow: row wrap;
    
    @media #{$large-up} {
        flex-flow: column wrap;
        height: $slot-height;
    }

    justify-content: flex-start;
    
    .collage-title {
        background: $color-accent-tertiary image-url('wave-tile.png');
        text-align: center;
        width: 100%;
        @media #{$xlarge-up} {
            min-height: rem-calc(150);
        }
        padding: rem-calc(30);
        h1 {
            margin: 0;
            span {
                display: block;
            }
            span.line1 {
                color: #fff;
                font-family: $font-tertiary;
                font-style: italic;
                font-size: rem-calc(36);
            }
            span.line2 {
                color: $color-primary;
                text-transform: uppercase;
                font-size: $font-size-larger;
            }
        }
        &.mobile {
            display: none;
            @media #{$medium-down} {
                display: block;
            }
        }
    }
    .collage-slot,
    .collage-title-wrapper {
        margin-bottom: ($collage-gutter);
        padding: 0 ($collage-gutter/2);
        flex: 0 1 auto;
    }
    .collage-slot {
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        max-height: $slot-height;
        overflow: hidden;
        @media #{$medium-down} {
            max-height: $slot-height/2;
            margin-bottom: $collage-gutter;
        }
        @media #{$small-down} {
            max-height: $slot-height/4;
            margin-left: 0;
            margin-right: 0;
        }
        .collage-text {
            position: absolute;
            z-index: 10;
//            top: 0;
            padding-top: 1rem;
//            @media #{$large-up} {
                top: auto;
                bottom: 0;
//            }
            left: 0;
            width: 100%;
            
            padding: 0 ($collage-gutter/2) 0 ($collage-gutter/2);
            text-align: center;
            color: #fff;
            font-size: $font-size-smaller;
            height: 100%;
            @media #{$medium-up} {
                height: rem-calc(75);
            }
            
            transition: height 0.5s ease-in-out;
        }
        .collage-text-header {
            font-weight: bold;
            text-transform: uppercase;
            font-size: $font-size-small;
            text-align: center;
        }
        .collage-text-body {
            display: block;
            
            @media #{$small-down} {
                display: none;
            }
            
            @media #{$medium-up} {
                max-height: 0;
                opacity: 0;
                transition: max-height 0.5s ease-in-out, opacity 0.2s ease-out;
                overflow: hidden;
            }
            
            
        }
        .collage-text-bg {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            height: 100%;
            padding: rem-calc(30) rem-calc(15) rem-calc(20) rem-calc(20);
            
            background-size: 100% 101%;
            background-position: center bottom;
//            background-color: rgba(0,0,0,0.6);
            
//            @media #{$large-up} {
                background-color: transparent;
                background-image: linear-gradient(to bottom, rgba(#000, 0) 0%, rgba(#000, 0.8) 90%);
                transition: background-size 0.5s ease-in-out;
//            }
            
        }
        img {
            position: relative;
            z-index: 1;
            flex-shrink: 0;
            min-width: 100%;
            min-height: 100%;
            max-width: none;
            width: auto;
//            transform: scale(1.02);
//            
//            transition: transform .75s ease-out;
        }
    }

    .collage-slot-content {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    // Collage slot hover-state animations
    .collage-slot:hover {
        .collage-text {
            height: 100%;
            
            @media #{$small-down} {
                 color: $color-accent-quinary;
                 transition: color 0.25s ease-in-out;
            }
        }
        .collage-text-bg {
            @media #{$medium-up} {
                justify-content: center;
                background-size: 100% 150%;

                transition: background-size 1.5s ease-in-out;
            }
        }
        
        .collage-text-body {
            @media #{$medium-up} {
                max-height: $slot-height;
                opacity: 1;
                transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in;
            }
        }
        
        img {
//            transform: scale(1);
        }
        
    }
    .collage-title-wrapper {
        @media #{$large-up} {
            order: 2;
        }
        
    }
    .slot1 {
        margin-top: 0;
        
        @media #{$large-up} {
            order: 1;
            margin-top: rem-calc(20);
        }
    }
    .slot2 {
        
        @media #{$medium-up} {
            
        }
        @media #{$large-up} {
            order: 3;
            max-height: rem-calc(292.5);
            margin-bottom: 0;
        }
    }
    .slot3 {
        
        @media #{$large-up} {
            order: 4;
            margin-top: rem-calc(30);
        }
    }
    .slot4 {
        @media #{$large-up} {
            order: 5;
            margin-top: rem-calc(35);
            max-height: $slot-height - rem-calc(50);
        }
    }
    // curve mask
    .slot1,
    .slot4 {
        &:before {
            display: block;
            content: '';
            position: absolute;
            bottom: 0;
            z-index: 15;
            width: 200px;
            height: $slot-height;
            @media (max-width: rem-calc(1400)) {}
            @media #{$medium-down} {
                display: none;
            }
        }
    }
    .slot1 {
        .collage-text-bg {
            @media #{$large-up} {
                padding-left: 30%;
            }
        }
        &:before {
            background: image-url('home-collage-mask-left.svg') no-repeat bottom left;
            left: -1px;
            @media (max-width: rem-calc(1400)) {
                left: -10%;
            }
            @media (max-width: rem-calc(1200)) {
                left: -15%;
            }
            @media (max-width: rem-calc(1100)) {
                left: -20%;
            }
        }
    }
    .slot4 {
        .collage-text-bg {
            @media #{$large-up} {
                padding-right: 20%;
            }
        }
        &:before {
            background: image-url('home-collage-mask-right.svg') no-repeat bottom right;
            right: -1px;
            @media (max-width: rem-calc(1400)) {
                right: -10%;
            }
            @media (max-width: rem-calc(1200)) {
                right: -15%;
            }
            @media (max-width: rem-calc(1100)) {
                right: -20%;
            }
        }
    }
}




/* Learn More Form
/* _______________________________________________________________ */

.learn-more-form {
    padding: rem-calc(100) 0;
    .header {
        text-align: center;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: bold;
        }
    }
    label {
        font-size: $font-size-large;
        margin-bottom: rem-calc(-10);
    }
}


/* Photo Button Teaser Slab
/* _______________________________________________________________ */

.photo-button-teaser-slab {
    .button-wrapper {
        @media #{$medium-only} {
            // padding: 0 rem-calc(10);
        }
    }
}


/* Question Form
/* _______________________________________________________________ */

.question-form {
    figure {
        position: relative;
        figcaption {
            position: absolute;
            bottom: 0;
            background: rgba($color-primary, 0.7);
            @include on-dark;
            font-family: $font-primary;
            font-weight: bold;
            margin-bottom: 0;
            @media #{$medium-down} {
                position: static;
                background: $color-primary;
                margin-bottom: rem-calc(20);
            }
        }
    }
}


/* Search Archives
/* _______________________________________________________________ */

.search-archives {
    background: $color-primary image-url('wave-tile-dark.png');
    @include on-dark;
    text-align: center;
    padding: rem-calc(30);
    margin-bottom: rem-calc(20);
    a.button {
        margin-bottom: rem-calc(10);
    }
    button {
        background: $color-accent-primary;
    }
    .separator {
        margin: 0;
        margin-bottom: rem-calc(10);
        text-transform: uppercase;
        font-size: $font-size-smaller;
        &:before,
        &:after {
            display: inline-block;
            content: '-';
        }
        &:before {
            margin-right: 5px;
        }
        &:after {
            margin-left: 5px;
        }
    }
    form.search-archives {
        padding: 0;
    }
}


/* Slab Carousel
/* _______________________________________________________________ */

.slab-carousel {
    background: #65c8c6 image-url("background-photo-texture1.png") no-repeat top center;
    background-size: cover;
    @include on-dark;
    margin-bottom: rem-calc(70);
    padding: rem-calc(65) 0 rem-calc(100) 0;
    @media #{$medium-down} {
        padding: rem-calc(40) 0;
    }
    .header {
        margin: 0 0 rem-calc(50) 0;
        @media #{$medium-down} {
            margin-bottom: rem-calc(20);
            text-align: center;
        }
    }


    .slick-slab-carousel {
        position: relative;
        padding: 0 rem-calc(40);
        button {
            background: none;
            padding: 0;
            margin: 0;
            margin: auto;
            height: rem-calc(50);
            font-size: rem-calc(50);
            position: absolute;
            bottom: 0;
            top: 0;
            outline: 0;
            &.slick-next {
                right: 10px;
            }
            &.slick-prev {
                left: 10px;
            }
        }
        .slide {
            text-align: center;
            font-style: italic;
            margin: 0 rem-calc(8);
            outline: 0;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-style: italic;
            }
            a {
                display: block;
                font-style: italic;
                outline: 0;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}


/* Social Feeds
/* _______________________________________________________________ */

.social-feeds {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &.header {
            margin-bottom: rem-calc(30);
        }
    }
}


/* Steps List
/* _______________________________________________________________ */

.steps-list {
    margin-bottom: rem-calc(20);
    .arc-block {
        @media #{$medium-down} {
            background-color: $color-accent-tertiary;
            padding: rem-calc(20);
        }
        .shape-wrapper {
            path#shape-wave {
                display: block;
                @media #{$medium-down} {
                    display: none;
                }
            }
            path#shape-color {
                @media #{$medium-down} {
                    display: none;
                }
            }
        }
    }
    ol.rich {
        background: #fff;
        padding: rem-calc(20);
        margin: rem-calc(-30) 0 rem-calc(20) 0;
        position: relative;
        z-index: 3;
        @media #{$small-down} {
            margin-top: 0;
        }
    }
    &.popout {
        margin-top: -15.375rem;
        @media #{$medium-down} {
            margin-top: 0;
        }
        .arc-block {
            margin-left: rem-calc(-100);
            @media #{$medium-down} {
                margin-left: 0;
                padding: rem-calc(30);
                width: 100%;
            }
        }
        ol.rich {
            @media #{$medium-down} {
                margin-top: 0;
            }
        }
    }
}


/* Story Teaser
/* _______________________________________________________________ */

.story-teaser {
    margin-bottom: rem-calc(20);
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: rem-calc(30);
    }
    a {
        @extend .arrow-link;
    }
}


/* Tabs
/* _______________________________________________________________ */

.tabs-section {
    .tabs-content {
        margin-top: rem-calc(-50);
        position: relative;
        z-index: 1;
        @media #{$small-down} {
            margin-top: 0;
            position: static;
            padding-top: rem-calc(10);
        }
    }
    .arc-block {
        @media #{$small-down} {
            background: $color-accent-tertiary;
            padding: rem-calc(20);
            width: 100%;
            .shape-wrapper {
                display: none;
            }
        }
    }
}