/* -------------------------------------------------------------- */
/*    Atoms - Tables
/* -------------------------------------------------------------- */


/* Default Table
/* _______________________________________________________________ */
table {
	border-collapse: collapse; 
	border-spacing: 0;
	width: 100%;
	font-size: $font-size-small;
	margin-bottom: rem-calc(30);
	tr {
		border-bottom: $color-shade-light 1px solid;
		&:last-child {
			border-bottom: 0;
		}
		&:nth-child(even) {
			// turn on to stripe
			// background: rgba(#000, 0.3);
		}
		th,
		td {
			padding: rem-calc(20) rem-calc(30);
		}
		th {
			text-align: left;
			background: $color-shade-light;
		}
		td {
			background: #fff;
		}
	}
	tfoot {

	}
}


/* Dark Table
/* _______________________________________________________________ */
table.dark {
	background: $color-accent-primary;
	@include on-dark;
	tr {
		border: 0;
		th {
			background: $color-primary;
		}
		td {
			background: none;
		}
	}
}


/* Responsive wrapper for tables
/* _______________________________________________________________ */
// requires js to add this wrapper div & scroll helper
// scroll helper is a small line of text with arrow that tells the user to scroll/swipe right
.table-responsive {
	&.active {
		overflow-x: scroll;
	}
}
.table-scroll-helper {
	display: none;
	font-size: rem-calc(10);
	text-align: right;
	position: relative;
	padding: 0 rem-calc(10) rem-calc(5) 0;
	margin-right: rem-calc(5);
	&:before {
		position: absolute;
		top: 3px;
		bottom: 0;
		left: auto;
		right: 0;
		height: rem-calc(10);
		padding-right: 0;
		@extend .fa;
		content: $fa-var-angle-right;
	}
	&.active {
		display: block;
	}
}
