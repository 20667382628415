/* -------------------------------------------------------------- */
/*    Molecules - Lists
/* -------------------------------------------------------------- */


/* Alphabetized List
/* _______________________________________________________________ */
ul.alpha-list {
	@extend .strip-list;
	column-count: 3;
	@media #{$medium-down} {
		column-count: 2;
	}
	@media #{$small-down} {
		column-count: 1;
	}
	h1, h2, h3, h4, h5, h6 {
		&.alpha-header {
			color: $color-primary;
			font-weight: bold;
			border-bottom: $color-shade 3px solid;
			text-align: center;
			margin-top: 0;
		}
	}
	& > li {
		display: block;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
		ul {
			@extend .strip-list;
			li {
				padding-bottom: rem-calc(20);
				&.filter-inactive {
					opacity: 0.4;
					a {
						color: $color-text;
						pointer-events: none;
						cursor: default;
					}
				}
			}
		}
	}
	p {
		margin-bottom: 0;
		&.item-name {

		}
		&.item-categories {
			text-transform: uppercase;
			font-size: $font-size-smaller;
		}
		&.item-detail {
			font-style: italic;
		}
	}
}


/* Events List
/* _______________________________________________________________ */
.events-list {
	background: #1c4188 image-url('background-photo-texture1.png') no-repeat bottom left;
	padding: rem-calc(30) rem-calc(90) rem-calc(40) rem-calc(40);
	margin-bottom: rem-calc(20);
	@media #{$medium-down} {
		padding-right: rem-calc(40);
	}
	@media #{$small-down} {
		padding: rem-calc(20);
	}
	header.events-list-header {
		@include on-dark;
		a {
			@extend .angle-link;
			font-size: rem-calc(18);
		}
	}
	a.featured-event {
		display: block;
		background: $color-primary;
		@include on-dark();
		margin-bottom: rem-calc(10);
		.row {
			@extend .collapse;
		}
		.featured-event-image {
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			img {
				flex-shrink: 0;
				min-width: 100%;
				min-height: 100%;
				max-width: none;
				width: auto;
			}
		}
		.event-details {
			padding: rem-calc(16);
			.date {
				font-size: $font-size-large;
				font-weight: bold;
			}
			.detail {
				font-family: $font-secondary;
			}
		}
	}
	ul {
		@extend .strip-list;
		li {
			&:first-child a {
				background: #fff;
				margin-left: 0;
				margin-right: 0;
				&:hover {
					background: lighten($color-accent-quinary, 40%);
				}
			}
			a {
				display: block;
				color: $color-text;
				@include clearfix;
				background: $color-shade-light;
				margin: 0 rem-calc(6) rem-calc(2);
				padding: rem-calc(20) rem-calc(25);
				&:hover {
					text-decoration: none;
					background: $color-shade-lighter;
				}
				.datebox {
					float: left;
					text-align: center;
					width: rem-calc(40);
					margin-right: rem-calc(30);
					.month {
						font-family: $font-tertiary;
						font-size: $font-size-small;
						text-transform: uppercase;
					}
					.day {
						font-size: rem-calc(36);
						line-height: rem-calc(31);
					}
				}
				.event-details {
					padding-left: rem-calc(70);
					h1, h2, h3, h4, h5, h6,
					p {
						margin-bottom: 0;
					}
					h1, h2, h3, h4, h5, h6 {
						color: $color-link;
					}
					p {
						font-size: $font-size-small;
					}
				}
			}
		}
	}
	// light option (homepage) 
	&.light {
		background: transparent;
		padding: 0;
		header.events-list-header {
			h1, h2, h3, h4, h5, h6 {
				color: $color-text;
			}
			a {
				color: $color-primary;
			}
		}
		a.featured-event {
			background: $color-accent-quinary;
		}
		ul {
			li,
			li:first-child {
				a {
					background: #fff;
					margin: 0 0 rem-calc(10) 0;
					&:hover {
						background: lighten($color-accent-quinary, 40%);
					}
					.event-details {
						.event-title {
							font-weight: bold;
						}
						.detail {
							font-family: $font-secondary;
						}
					}
				}
			}
		}
	}
}


/* Fact List
/* _______________________________________________________________ */
ul.fact-list {
	@extend .strip-list;
	li {
		border-left: $color-shade 1px solid;
		padding: rem-calc(5) 0 rem-calc(5) rem-calc(20);
		margin-bottom: rem-calc(20);
		.fact {
			font-size: $font-size-larger;
		}
		.fact-detail {
			font-size: $font-size-smaller;
		}
	}
}


 /* Meta List (for news stories)
 /* _______________________________________________________________ */ 
ul.meta-list {
	@extend .inline;
	font-family: $font-secondary;
	font-size: $font-size-small;
	margin-left: 0;
	li {
		line-height: rem-calc(16);
		&:not(:last-child) {
			padding-right: 7px;
			border-right: $color-text 1px solid;
			margin-right: 5px;
		}
	}
}


/* Rich Ordered List
/* _______________________________________________________________ */
ol.rich {
	// list-style: decimal-leading-zero;
	li {
		border-left: $color-accent-secondary rem-calc(9) solid;
		text-indent: 0;
		margin-left: 0;
		margin-bottom: rem-calc(4);
		padding: 0 0 rem-calc(50) rem-calc(30);
		position: relative;
		&:before {
			content: counter(brand-counter);
			margin: 0;
			position: absolute;
			top: 0;
			left: rem-calc(5);
			font-weight: bold;
			color: $color-text;
			line-height: 22px;
			@media #{$medium-down} {
				// left: rem-calc(5);
			}
		}
		h1, h2, h3, h4, h5, h6 {
			&.header {
				font-weight: bold;
				margin-bottom: 0;
			}
		}
		p:last-child {
			margin-bottom: 0;
		}
	}
}


