/* -------------------------------------------------------------- */
/*    Molecules - News
/* -------------------------------------------------------------- */


/* News Article Meta
/* _______________________________________________________________ */
.news-article-meta {
	.tweet-link {
		font-weight: bold;
		margin-bottom: rem-calc(20);
		display: inline-block;
	}
	.article-title {
		font-size: rem-calc(60);
		line-height: rem-calc(68);
		font-family: $font-secondary;
		@media #{$small-down} {
			font-size: $font-size-largest;
			line-height: rem-calc(45);
		}
	}
	.article-subtitle {
		// font-size: $font-size-larger;
		@extend .delta;
		margin-bottom: rem-calc(20);
		@media #{$small-down} {
			// font-size: $font-size-large;
		}
	}
	.byline {
		@extend .zeta;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: rem-calc(5);
	}
	ul.meta-list {
		li {
			font-size: $font-size-default;
		}
	}
}


/* News Category Listing
/* _______________________________________________________________ */
.news-category-listing {
	ul.category {
		@extend .inline;
	}
	.article {
		@include news-dot-lines();
		padding: 0 0 0 rem-calc(20);
		margin-top: rem-calc(10);
		figure {
			margin-bottom: rem-calc(20);
		}
		h1, h2, h3, h4, h5, h6 {
			&.article-title,
			&.article-date {
				font-size: $font-size-default;
				line-height: $font-size-default;
			}
			&.article-title {
				font-weight: bold;
			}
			&.article-date {
				font-family: $font-secondary;
			}
		}
		.article-excerpt {
			margin-top: rem-calc(15);
		}
	}
	&.simplified {
		ul.category {
			display: none;
		}
		.article:before {
			background-color: $color-shade-dark;
		}
		@each $label in $story-labels {
		$label-name: nth($label, 1);
		$label-color: nth($label, 2);
			&.#{$label-name} {
				figure img {
					border-top: $label-color 3px solid;
				}
			}
		}
	}
}


/* News Feature Photo
/* _______________________________________________________________ */
.news-feature-photo {
	margin: rem-calc(20) 0;
	figure {
		position: relative;
		figcaption {
			position: absolute;
			bottom: 0;
			right: 0;
			margin-bottom: 0;
			padding: rem-calc(5) rem-calc(10);
			background: rgba(#000, 0.5);
			color: #fff;
			text-align: right;
			font-size: $font-size-smaller;
		}
	}
}


/* News List
/* _______________________________________________________________ */
.news-list {
	background: #fff;
	padding: rem-calc(20);
	margin-bottom: rem-calc(20);
	h1, h2, h3, h4, h5, h6 {
		&.header {
			font-size: $font-size-larger;
			font-weight: bold;
		}
		&.article-title {
			font-size: $font-size-default;
			line-height: $font-size-default + rem-calc(6);
			font-weight: bold;
			margin-bottom: 0;
		}
		&.article-subtitle {
			font-size: $font-size-default;
			margin-top: 0;
			margin-bottom: 0;
		}
	}
	ul.articles {
		@include news-dot-lines();
		@extend .strip-list;
		& > li {
			margin: -5px 0 rem-calc(30) rem-calc(20);
		}
	}
	p.more-link {
		margin-bottom: 0;
	}
}


/* News List Large
/* _______________________________________________________________ */
.news-list-large {
	margin-bottom: rem-calc(50);
	h1, h2, h3, h4, h5, h6 {
		&.header {
			font-size: $font-size-larger;
			font-weight: bold;
		}
	}
	ul.articles {
		@include news-dot-lines();
		@extend .strip-list;
		& > li {
			margin: -5px 0 rem-calc(30) rem-calc(20);
			.article-title,
			.article-date {
				font-weight: bold;
				font-size: $font-size-default;
				line-height: $font-size-default + rem-calc(6);
				margin-bottom: 0;
			}
			.article-excerpt {
				margin-top: rem-calc(16);
			}
			figure {
				@media #{$small-down} {
					margin-bottom: rem-calc(30);
				}
			}
			&.featured-article {
				h1, h2, h3, h4, h5, h6 {
					&.article-title {
						font-size: $font-size-larger;
						// font-weight: normal;
						line-height: $font-size-larger + rem-calc(2);
					}
				}
				.article-date {
					font-weight: normal;
				}
			}
		}
	}
}