/* -------------------------------------------------------------- */
/*    Molecules - Messaging
/* -------------------------------------------------------------- */

/* Alert
/* _______________________________________________________________ */
.alert {
	background: $color-accent-quinary;
	padding: rem-calc(20) 0;
	.alert-content {
		position: relative;
		padding-left: rem-calc(50); // room for icon in small views
		&:before {
			@extend .fa;
			content: $fa-var-exclamation-circle;
			position: absolute;
			top: 0;
			left: 0;
			color: #fff;
			font-size: rem-calc(40);
		}
		&:after {
			display: block;
			content: '';
			position: absolute;
			z-index: 5;
			top: -32px;
			left: 50px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 12px 12px 12px;
			border-color: transparent transparent $color-accent-quinary transparent;
		}
		a {
			color: #fff;
			text-decoration: underline;
			&.details {
				&:after {
					@extend .fa;
					content: $fa-var-long-arrow-right;
					margin-left: 3px;
				}
			}
		}
		h1, h2, h3, h4, h5, h6 {
			&.header {
				font-weight: bold;
				font-size: $font-size-default;
				text-transform: uppercase;
				margin-bottom: 0;
			}
		}
		p {
			font-size: $font-size-small;
			margin-bottom: 0;
		}
	}
	a.close {
		font-size: 0;
		&:before {
			@extend .fa;
			content: $fa-var-close;
			font-size: $font-size-large;
			color: #fff;
			border-left: #fff 1px solid;
			padding: rem-calc(3) rem-calc(7);
		}
	}
	&.alert-notice {
		background: $color-accent-tertiary;
		.alert-content:after {
			border-color: transparent transparent $color-accent-tertiary transparent;
		}
	}
	&.alert-emergency {
		$emergency-color: #d83a3d;
		background: $emergency-color;  // emergency red not used anywhere else so it's not in the vars/palette
		@include on-dark();
		.alert-content:after {
			border-color: transparent transparent $emergency-color transparent;
		}
	}
}


/* Emergency Homepage
/* _______________________________________________________________ */
body.emergency {
	background: $color-accent-primary;
	@include on-dark();
	text-align: center;
	header {
		background: $color-primary image-url('wave-header.png') no-repeat top center;
		padding: rem-calc(30) rem-calc(10);
		margin-bottom: rem-calc(60);
		@media #{$medium-down} {
			margin-bottom: rem-calc(30);
		}
	}
	h1, h2, h3, h4, h5, h6 {
		&.header {
			text-transform: uppercase;
			margin-bottom: rem-calc(50);
			@media #{$medium-down} {
				margin-bottom: rem-calc(30);
			}
			@media #{$small-down} {
				font-size: $font-size-largest;
			}
			&:before {
				@extend .fa;
				content: $fa-var-exclamation-circle;
				color: $color-accent-quinary;
				margin-right: rem-calc(20);
			}
		}
	}
}