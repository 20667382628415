/* -------------------------------------------------------------- */
/*    Organisms - Fact Slabs
/* -------------------------------------------------------------- */


/* Fact Slab
/* _______________________________________________________________ */
.fact-slab {
	@include on-dark();
	background: $color-accent-primary;
	padding: rem-calc(60) 0;
	position: relative;
	overflow: hidden;
	margin-bottom: rem-calc(40);
	@media #{$small-down} {
		padding: rem-calc(30) 0;
	}
	.background {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		opacity: 0.2;
		img {
			width: 100%;
			@media #{$small-down} {
				max-width: none;
				width: auto;
			}
		}
	}
	.fact-slab-text {
		@media #{$small-down} {
			margin-bottom: rem-calc(20);
		}
	}
	.shape-wrapper {
		display: none;
	}
	ul.fact-list {
		li {
			.fact {
				font-size: $font-size-largest;
			}
			.fact-detail {
				font-size: $font-size-default;
			}
		}
	}
	&.theme-light {
		background: $color-accent-secondary;
	}
	&.theme-medium {
		background: $color-accent-primary;
	}
	&.theme-dark {
		background: $color-primary;
	}
	&.full-color {
		.background {
			opacity: 1;
		}
		ul.fact-list {
			li {
				border-left-color: rgba(#fff, 0.2);
			}
		}
		.fact-slab-text {
			color: $color-text;
			@extend .arc-block;
			@media #{$medium-down} {
				background: #fff;
				padding: rem-calc(20);
			}
			h1, h2, h3, h4, h5, h6 {
				color: $color-primary;
			}
			p {
				color: $color-text;
			}
			a {
				color: $color-primary;
			}
			.shape-wrapper {
				display: block;
				path#shape-color {
					fill: #fff;
				}
				@media #{$medium-down} {
					path#shape-color {
						display: none;
					}
				}
			}
		}
	}
}


/* Fact Slab with Photo
/* _______________________________________________________________ */
.fact-slab-photo {
	padding: rem-calc(50) 0 rem-calc(20) 0;
	margin-bottom: rem-calc(60);
	.photo-wrapper {
		background: $color-accent-quinary;
		padding: 0 rem-calc(100) rem-calc(100) rem-calc(100);
		position: relative;
		@media #{$medium-down} {
			padding: 0 rem-calc(30) rem-calc(30) rem-calc(30);
		}
		figure {
			margin-bottom: 0;
			img {
				margin-top: rem-calc(-30);
			}
		}
		.fact-slab-text {
			@extend .arc-block;
			position: absolute;
			right: 0;
			bottom: rem-calc(-30);
			max-width: 60%;
			padding-bottom: rem-calc(10);
			color: $color-text;
			@media #{$medium-down} {
				position: static;
				max-width: none;
				background: #fff;
				padding: rem-calc(20);
			}
			h1, h2, h3, h4, h5, h6,
			a {
				color: $color-accent-quinary;
			}
			.shape-wrapper {
				path#shape-color {
					fill: $color-bg;
				}
				@media #{$medium-down} {
					path#shape-color {
						display: none;
					}
				}
			}
		}
	}
	ul.fact-list {
		@media #{$small-down} {
			margin-top: rem-calc(30);
		}
		li {
			.fact {
				color: $color-accent-quinary;
				font-size: $font-size-largest;
			}
			.fact-detail {
				font-size: $font-size-default;
			}
		}
	}
	&.color2 {
		@include fact-slab-color-modifier($color-accent-tertiary);
	}
	&.color3 {
		@include fact-slab-color-modifier($color-accent-quaternary);
	}
}