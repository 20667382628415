/* -------------------------------------------------------------- */
/*    Mixins
/* -------------------------------------------------------------- */

// generate the image url by prefixing the layout image path
@function image-url($url) {
  @return url("#{$image-url-path}#{$url}");
}

// style input placeholders
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder           {@content}
  &:-ms-input-placeholder      {@content}  
}


/* On Dark
/* _______________________________________________________________ */ 
@mixin on-dark() {
	$color-text-on-dark: #fff;
	$color-link-on-dark: #fff;
	color: $color-text-on-dark;
	p,
	h1, h2, h3, h4, h5, h6,
	blockquote cite {
		color: $color-text-on-dark;
	}
	a:not(.button) {
		color: $color-link-on-dark;
		&:hover, &:focus {
			text-decoration: underline;
		}
		&:before,
		&:after {
			text-decoration: none;
		}
	}
	ul {
		li {
			&:before {
				color: #fff;
			}
		}
	}
	nav.primary-nav {
		a {
			&:hover {
				text-decoration: none;
			}
		}
	}
}


/* Dotted Lines
/* _______________________________________________________________ */ 
@mixin dotted-lines-defaults {
	display: block;
	content: '..........................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................';
	font-size: 24px;
	line-height: 28px;
	margin-top: -20px;
	color: $color-shade;
	letter-spacing: 6px;
}

$constrain-content: '............';

@mixin dotted-lines($position, $constrain: false) {
	overflow: hidden;
	@if $position == "below" {
		&:after {
			@include dotted-lines-defaults;
			@if $constrain {
				content: $constrain-content;
			}
		}
	}
	@if $position == "above" {
		&:before {
			margin-bottom: rem-calc(20);
			@include dotted-lines-defaults;
			@if $constrain {
				content: $constrain-content;
			}
		}
	}
	@if $position == "above-below" {
		&:after,
		&:before {
			@include dotted-lines-defaults;
			@if $constrain {
				content: $constrain-content;
			}
		}
		&:before {
			margin-bottom: rem-calc(20);
		}
		&:after {
			margin-top: rem-calc(5);
		}
	}
}


/* Blockquote Icon (quote icon with gray lines on either side)
/* currently, you must set the margin-top offset, width, height and abs top value manually.
/* this allows scaled sizing on the contact block (width 100%).
/* cool future development: do this as a function.
/* _______________________________________________________________ */ 
@mixin quote-icon() {
	position: relative;
	&:before {
		position: absolute;
		display: block;
		content: '';
		background: image-url('icon-blockquote.svg') no-repeat top center;
		background-size: contain;
		right: 0;
		left: 0;
		bottom: auto;
		margin: auto;
	}
}


/* Dot-line-dot on left side of news items
/* _______________________________________________________________ */ 
@mixin news-dot-lines() {
	border-left: $color-shade-dark 2px solid;
	margin-top: rem-calc(30);
	margin-bottom: rem-calc(30);
	position: relative;
	$circle-size: 8px;
	$circle-offset: -$circle-size - 5;
	&:before,
	&:after {
		display: inline-block;
		content: '';
		position: absolute;
		left: -$circle-size/1.6;
		width: $circle-size;
		height: $circle-size;
		border-radius: $circle-size/2;
		background: $color-shade-dark;
	}
	&:before {
		top: $circle-offset;
		background: $color-accent-quinary;
	}
	&:after {
		bottom: $circle-offset;
	}
}


/* Style for header search placeholder
/* _______________________________________________________________ */ 
@mixin header-search-placeholder() {
	text-transform: uppercase;
	color: $color-text;
	font-size: $font-size-smaller;
	line-height: 18px;
}


/* Fact Slab with Photo: Color Options
/* _______________________________________________________________ */ 
@mixin fact-slab-color-modifier($color) {
	.photo-wrapper {
		background: $color;
		.fact-slab-text {
			h1, h2, h3, h4, h5, h6,
			a {
				color: $color;
			}
		}
	}
	ul.fact-list {
		li {
			.fact {
				color: $color;
			}
		}
	}
}


/* Layout Helpers
/* _______________________________________________________________ */ 

@mixin clearfix() {
	*zoom: 1;
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

// responsive flex cols
@mixin flex-cols($size, $n) {
	.#{$size}-flex-#{$n} {
		column-count: $n;
		overflow: hidden;
	}
}
@each $cols in 1, 2, 3, 4, 5 {
	@include flex-cols(small, $cols);
	// @media #{$medium-up} {
	// 	@include flex-cols(medium, $cols);
	// }
	// @media #{$large-up} {
	// 	@include flex-cols(large, $cols);
	// }
}


/* _______________________________________________________________ */ 

/* CSS Transition
	Usage: @include transition(width,0.3s,ease-out);
 */
@mixin transition($transition-property, $transition-time, $method) {
	-webkit-transition: $transition-property $transition-time $method;
	-moz-transition: $transition-property $transition-time $method;
	-ms-transition: $transition-property $transition-time $method;
	-o-transition: $transition-property $transition-time $method;
	transition: $transition-property $transition-time $method;
}



/* _______________________________________________________________ */ 

// Foundation stuff that is useful

// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

// This is the default html and body font-size for the base rem value.
$rem-base: 16px !default;

// IMPORT ONCE
// We use this to prevent styles from being loaded multiple times for components that rely on other components.
$modules: () !default;

@mixin exports($name) {
	// Import from global scope
	$modules: $modules !global;
	// Check if a module is already on the list
	$module_index: index($modules, $name);
	@if (($module_index == null) or ($module_index == false)) {
		$modules: append($modules, $name) !global;
		@content;
	}
}

//
// @functions
//

// RANGES
// We use these functions to define ranges for various things, like media queries.
@function lower-bound($range) {
	@if length($range) <= 0 {
		@return 0;
	}
	@return nth($range, 1);
}

@function upper-bound($range) {
	@if length($range) < 2 {
		@return 999999999999;
	}
	@return nth($range, 2);
}

// STRIP UNIT
// It strips the unit of measure and returns it
@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

// TEXT INPUT TYPES

@function text-inputs( $types: all, $selector: input ) {

	$return: ();

	$all-text-input-types:
		text
		password
		date
		datetime
		datetime-local
		month
		week
		email
		number
		search
		tel
		time
		url
		color
		textarea;

	@if $types == all { $types: $all-text-input-types; }

	@each $type in $types {
		@if $type == textarea {
			@if $selector == input {
				$return: append($return, unquote('#{$type}'), comma)
			} @else {
				$return: append($return, unquote('#{$type}#{$selector}'), comma)
			}
		} @else {
			$return: append($return, unquote('#{$selector}[type="#{$type}"]'), comma)
		}
	}

		@return $return;

}

// CONVERT TO REM
@function convert-to-rem($value, $base-value: $rem-base) {
	$value: strip-unit($value) / strip-unit($base-value) * 1rem;
	@if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
	@return $value;
}

@function data($attr) {
	@if $namespace {
		@return '[data-' + $namespace + '-' + $attr + ']';
	}

	@return '[data-' + $attr + ']';
}

// REM CALC

// New Syntax, allows to optionally calculate on a different base value to counter compounding effect of rem's.
// Call with 1, 2, 3 or 4 parameters, 'px' is not required but supported:
//
//   rem-calc(10 20 30px 40);
//
// Space delimited, if you want to delimit using comma's, wrap it in another pair of brackets
//
//   rem-calc((10, 20, 30, 40px));
//
// Optionally call with a different base (eg: 8px) to calculate rem.
//
//   rem-calc(16px 32px 48px, 8px);
//
// If you require to comma separate your list
//
//   rem-calc((16px, 32px, 48), 8px);

@function rem-calc($values, $base-value: $rem-base) {
	$max: length($values);

	@if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

	$remValues: ();
	@for $i from 1 through $max {
		$remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
	}
	@return $remValues;
}


@function em-calc($values) {
	$remValues: rem-calc($values);

	$max: length($remValues);

	@if $max == 1 { @return strip-unit(nth($remValues, 1)) * 1em; }

	$emValues: ();
	@for $i from 1 through $max {
		$emValues: append($emValues, strip-unit(nth($remValues, $i)) * 1em);
	}
	@return $emValues;
}

// Stretch the given element to fill the nearest ancestor with
// a display of "absolute" or "relative"
@mixin pseudo-cover ($beneath:false) {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	content: '';
	display: block;
	
	> img {
		max-width: none;
		width: 100%;
	}
	
	@if $beneath {
		z-index: 0;
	}
}


// Applies a semi-transparent layer on top of a pseudo-covered image
// to make it darker
@mixin color-overlay( $color:#000, $opacity:0.5 ) {
	
	
	&::after {
		@include pseudo-cover;
		background-color: rgba($color, $opacity);
	}
	
	
	.mix-blend-mode & {
		> img {
			opacity: $opacity * 0.5;
		}
		
		&::after {
			background-color: rgba($color, 1);
			mix-blend-mode: multiply;
		}
	}
	
}

@mixin slab-photo-bg($color:$color-primary, $opacity:0.9) {
	position: relative;
	overflow: hidden;
	.image-background {
		@include color-overlay($color, $opacity);
	}
	
	.row {
		position: relative;
	}
	
}