/* -------------------------------------------------------------- */
/*    Molecules - Navigation
/* -------------------------------------------------------------- */


/* Breadcrumbs
/* _______________________________________________________________ */
ul.breadcrumbs {
	$icon-font-size: 14px;
	@extend .inline;
	@include dotted-lines(below);
	margin-top: rem-calc(40);
	margin-bottom: 0;
	@media #{$medium-down} {
		margin-top: rem-calc(20);
	}
	@media #{$small-down} {
		margin-top: rem-calc(10);
		&:after {
			margin-top: 0;
		}
	}
	li {
		margin-bottom: rem-calc(20);
		margin-right: rem-calc(5);
		font-size: $font-size-small;
		@media #{$small-down} {
			font-size: $font-size-smallest;
			margin-bottom: 0;
		}
		&:first-child {
			a {
				font-size: 0;
				&:before {
					font-size: $icon-font-size; // rems don't override here for some reason.
					@extend .fa;
					content: $fa-var-home;
				}
			}
		}
		&:last-child {
			margin-right: 0;
			a {
				&:after {
					display: none;
				}
			}
		}
		a {
			text-decoration: underline;
			&:after {
				display: inline-block;
				content: '-';
				margin-left: rem-calc(10);
				font-size: $icon-font-size;
			}
		}
	}
}


/* Lancer Dashboard
/* _______________________________________________________________ */

// control the size/functionality of the dashboard
$dashboard-width-large: rem-calc(500);
$dashboard-width-small: rem-calc(300);
.lancer-dashboard {
	width: $dashboard-width-large;
	@media #{$small-only} {
		width: $dashboard-width-small;
	}
}
.move-left .inner-wrap {
	transform: translate3d(-$dashboard-width-large, 0, 0);
	@media #{$small-only} {
		transform: translate3d(-$dashboard-width-small, 0, 0);
	}
}

// actual styles for the dashboard
.lancer-dashboard {
	background: $color-primary image-url('wave-tile-dark.png');
	@include on-dark;
	.dashboard-wrapper {
		padding: rem-calc(40) rem-calc(30) rem-calc(20) rem-calc(60);
		position: relative;
		@media #{$small-only} {
			padding: rem-calc(20);
		}
		a.offcanvas-toggle {
			position: absolute;
			top: rem-calc(43);
			left: rem-calc(18);
			display: inline-block;
			width: rem-calc(30);
			height: rem-calc(30);
			border-radius: rem-calc(15);
			line-height: rem-calc(30);
			text-align: center;
			color: #fff;
			background: $color-accent-primary;
			&:hover {
				background: lighten($color-accent-primary, 5%);
			}
			@media #{$small-only} {
				position: static;
				margin-bottom: rem-calc(20);
			}
		}
		a {
			text-decoration: underline;
			&:hover {
				color: lighten($color-accent-quinary, 40%);
			}
		}
		h1, h2, h3, h4, h5, h6 {
			&.dashboard-header {
				font-size: $font-size-larger;
				margin-top: rem-calc(3);
			}
			&.dashboard-subheader {
				font-family: $font-secondary;
				margin-bottom: 0;
				font-size: rem-calc(18);
				line-height: rem-calc(16);
			}
			&.dashboard-list-header {
				font-size: $font-size-large;
				font-family: $font-tertiary;
				font-style: italic;
			}
		}
		ul {
			margin-bottom: rem-calc(30);
			li {
				.dashboard-list-item-title {
					font-weight: bold;
				}
				.dashboard-list-item-detail {
					font-family: $font-secondary;
				}
			}
		}
	}
}

// main content area overlay: make gray-out color less stupid
.move-left a.exit-off-canvas {
	background: rgba(#000, 0.5);
	&:hover {
		background: rgba(#000, 0.5);
	}
}


/* Pagination
/* _______________________________________________________________ */
ul.pagination {
	@extend .strip-list;
	@extend .inline;
	margin: rem-calc(20) 0;
	text-align: center;
	li {
		margin: 0;
		a {
			display: inline-block;
			padding: rem-calc(3) rem-calc(10);
			background: lighten($color-shade-light, 5%);
			color: $color-text;
			border-radius: rem-calc(5);
			min-width: rem-calc(30);
			text-align: center;
			&:hover {
				text-decoration: none;
				background: lighten($color-shade-light, 8%);
			}
		}
		&.current a {
			background: $color-accent-primary;
			color: #fff;
			&:hover {
				background: lighten($color-accent-primary, 10%);
			}
		}
		&.prev a,
		&.next a,
		&.ellipsis a {
			background: transparent;
			color: $color-text;
		}
		&.ellipsis {
			margin: 0 rem-calc(10);
		}
		&.prev a,
		&.next a {
			color: $color-accent-primary;
		}
	}
}

/* Primary Nav
/* _______________________________________________________________ */
nav.primary-nav {
	ul.nav-list {
		@extend .inline;
		margin-bottom: 0;
		li {
			font-size: $font-size-large;
			margin-right: rem-calc(80);
			@media (max-width: rem-calc(1170)) {
				margin-right: rem-calc(30);
			}
			&:last-child {
				margin-right: 0;
			}
			@media #{$small-down} {
				display: block;
				margin-right: 0;
				margin-bottom: rem-calc(10);
			}
			&.selected,
			span.currentbranch0 {
				a {
					color: $color-accent-senary;
				}
			}
			a {
				&:hover {
					text-decoration: none;
					color: $color-accent-quinary;
				}
			}
		}
	}
}


/* Secondary Nav
/* _______________________________________________________________ */
nav.secondary-nav {
	background: #fff;
	padding: rem-calc(30) rem-calc(20);
	margin-bottom: rem-calc(20);
	.nav-title {
		text-transform: uppercase;
		font-weight: bold;
	}
	& > ul {
		@extend .strip-list;
		@extend .loose-list;
		margin-left: rem-calc(5);
		li {
			font-size: $font-size-small;
			margin-left: 0;
			text-indent: rem-calc(-3);
			&:before {
				margin-left: 0;
				margin-right: rem-calc(4);
			}
			span[class*="currentbranch"] {
				font-weight: bold;
			}
		}
	}
	
	h2, h3, h4, h5, h6 {
		&.nav-title {
			font-size: 1rem;
		}
	}
}


/* Tabs
/* _______________________________________________________________ */

%indented-tab {
    a {
        padding-left: rem-calc(40);
        text-indent: -9px;
        &:before {
            display: inline-block;
            content: '—';
            color: currentColor;
            margin-right: 3px;
        }
    }
}


.tabs-container {
	margin-bottom: rem-calc(20);
	ul.tabs {
		@extend .strip-list;
		margin-top: rem-calc(30);
//		@media #{$small-down} {
//			display: none;
//		}
		li {
			display: block;
			float: none;
			&.indent {
                @extend %indented-tab;
			}
			&.active, &.r-tabs-state-active {
				a {
					background: none;
					color: $color-primary;
					border-bottom-color: $color-primary;
					&:hover {
						color: $color-primary;
						border-bottom-color: $color-primary;
					}
				}
			}
			a {
				display: block;
				background: none;
				padding: rem-calc(15) 0 rem-calc(10);
				color: $color-shade-dark;
				border-bottom: $color-shade 5px solid;
				font-size: $font-size-small;
				&:hover {
					text-decoration: none;
					background: none;
					color: $color-shade-darker;
					border-bottom-color: $color-shade-darker;
				}
				&:focus {
					text-decoration: none;
				}
			}
		}
	}
	.tabs-content {
		background: #fff;
        @media #{$medium-up} {
          padding: rem-calc(50) rem-calc(40);
        }
		.content {
            
//            &:target::before {
//                content: '';
//                display: block;
//                height: rem-calc(300);
//                margin-top: rem-calc(-300);
//            }
            
			h1, h2, h3, h4, h5, h6 {
				text-transform: uppercase;
			}
			
			h2 {
				@extend .gamma;
			}
		
			h3 {
				@extend .delta;
			}
			h4 {
				@extend .epsilon;
			}
			h5, h6 {
				@extend .zeta;
			}
		}
	}
}

/* Replaces "display"-based visibility (from the plugin) with max-height-based visibility */
.r-tabs {
    .r-tabs-nav {
        padding-left: 0.9375rem;
    }
}

.r-tabs .r-tabs-panel {
    padding: 15px;
    display: block;
    overflow: hidden;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.r-tabs .r-tabs-panel.r-tabs-state-active {
    max-height: none;
}


/* Accordion
/* _______________________________________________________________ */

/* Accordion responsive breakpoint (overrides plugin default) */

.r-tabs-accordion-title {
    .indent {
      @extend %indented-tab;
    }
}

@media #{$medium-up} {
    .r-tabs .r-tabs-nav {
        display: block;
    }

    .r-tabs .r-tabs-accordion-title {
        display: none;
    }
}

  

@media #{$small-down} {
    .r-tabs { 
        .r-tabs-nav {
            display: none;
        }

      
      .r-tabs-accordion-title {
          a {
            position: relative;
            z-index: 1;
            display: block;
            height: auto;
            padding: 0.9375rem 1rem 0.625rem 1rem;
            
            color: $color-shade-dark;
            background-color: transparent;
            border-bottom-style: solid;
            border-bottom-width: 5px;
            border-bottom-color: currentColor;
            
            &:hover, &:focus, &:active {
              color: $color-shade-darker;
              text-decoration: none;
            }
            
            &:after { 
                 content: "\f196";
                font-family: 'FontAwesome';
                padding-left: 5px;
                float: right;
                font-size: 20px;
              }
          }
        &.r-tabs-state-active a {
          &:hover, &:active, &:focus {
                color: $color-primary;
              }
        }
        
        
          .indent {
            a { 
              text-indent: 0;
              &:before {
                color: currentColor;
              }
            }
          }
        }
      
        .r-tabs-state-active {
            
            a {
                color: $color-primary;
//                border-bottom-color: $color-primary;
                &:after {
                    content: "\f147";
                }
            }
        }
        
        .r-tabs-panel {
            position: relative;
            z-index: 10;
            background-color: white;
            margin-bottom: 1rem;
            transition: max-height 0.75s;
        }
    }
}




/* Tabs that switch to accordion at smaller screen
/* _______________________________________________________________ */

.tabs-to-accordion {
	@media #{$medium-up} {
		.accordion-navigation {
			> a {
				display: none;
			}
		}
	}

}


/* Utility Nav
/* _______________________________________________________________ */
nav.utility-nav {
	@media #{$medium-down} {
		text-align: center;
	}
	ul.nav-list {
		@extend .inline;
		margin: rem-calc(5) 0;
		&.links {
			float: left;
			@media #{$medium-down} {
				float: none;
			}
			li {
				@media (max-width: 24em) {
					margin-right: rem-calc(5);
				}
			}
		}
		&.tools {
			float: right;
			@media #{$medium-down} {
				display: none;
			}
		}
		li {
			font-size: $font-size-smaller;
			text-transform: uppercase;
			&.logo-mobile {
				display: none;
				@media #{$medium-down} {
					display: inline-block;
					img {
						max-width: 200px;
					}
				}
				@media #{$small-down} {
					img {
						max-width: 150px;
					}
				}
				@media (max-width: 24em) {
					margin-right: rem-calc(10);
					img {
						max-width: 135px;
					}
				}
			}
			&.highlight {
				font-weight: bold;
			}
			&.separate {
				margin-right: rem-calc(5);
				&:after {
					display: inline-block;
					content: '';
					width: 1px;
					height: 14px;
					background: #fff;
					margin-left: rem-calc(5);
					margin-bottom: -3px;
				}
			}
		}
	}
}


/* Utility Nav Tools
/* _______________________________________________________________ */
ul.nav-list.tools {
	@media #{$medium-down} {
		@include clearfix();
		display: inline-block;
	}
	li {
		font-size: $font-size-smaller;
		text-transform: uppercase;
		@media #{$medium-down} {
			display: block;
			float: left;
		}
		form.search {
			.search-options {
				display: inline-block;
				@media #{$medium-down} {
					display: block;
				}
			}
			input[type="radio"] {
				margin: 0;
				cursor: pointer;
				display: none;
			}
			input[type="radio"] + label {
				color: #fff;
				font-size: $font-size-smaller;
				margin-right: 10px;
				padding-left: 10px;
				position: relative;
				&:before,
				&:after {
					display: inline-block;
					content: '';
					position: absolute;
					background: $color-shade;
				}
				&:before {
					$circle-size: 10px;
					top: 2px;
					left: -$circle-size/2;
					width: $circle-size;
					height: $circle-size;
					border-radius: $circle-size/2;
				}
				&:after {
					$circle-size: 4px;
					top: 5px;
					left: -$circle-size/2;
					width: $circle-size;
					height: $circle-size;
					border-radius: $circle-size/2;
				}
			}
			input[type="radio"]:checked + label {
				// background: gray;
				&:after {
					background: $color-text;
				}
			}
			input[type="text"] {
				display: inline-block;
				width: rem-calc(130);
				height: auto;
				margin: 0;
				font-size: $font-size-smaller;
				text-align: right;
				padding: rem-calc(3) rem-calc(20) rem-calc(3) rem-calc(5);
			}
			::-webkit-input-placeholder {
				@include header-search-placeholder();
			}
			::-moz-placeholder {
				@include header-search-placeholder();
			}
			:-ms-input-placeholder {
				@include header-search-placeholder();
			}
			:-moz-placeholder {
				@include header-search-placeholder();
			}
			button[type="submit"] {
				background: transparent;
				border: 0;
				border-radius: 0;
				margin: 0;
				padding: rem-calc(5);
				margin-left: rem-calc(-26);
				color: $color-text;
				float: right;
				margin-top: rem-calc(-26);
				box-shadow: none;
				&:before {
					@extend .fa;
					content: $fa-var-search;
				}
				@media #{$small-down} {
					font-size: $font-size-small;
					margin-top: 0;
					margin-right: rem-calc(10);
				}
			}
		}
		a {
			&.lancer-dashboard-toggle {
				text-align: left;
				@media #{$medium-down} {
					display: block;
					padding: rem-calc(5) 0 rem-calc(5) rem-calc(20);
					margin-left: rem-calc(20);
					span {
						display: block;
					}
				}
				&:after {
					@extend .fa;
					content: $fa-var-angle-right;
					margin-left: 5px;
				}
			}
		}
	}
}