/* -------------------------------------------------------------- */
/*    Organisms - Grids
/* -------------------------------------------------------------- */


/* Block Button Grid
/* _______________________________________________________________ */
.block-button-grid {
	h1, h2, h3, h4, h5, h6 {
		margin-bottom: rem-calc(40);
	}
	ul {
		li {
			a.block-button {
				display: block;
				margin-bottom: 0;
				@extend .dark;
			}
		}
	}
}


/* Block Button Grid Slab
/* _______________________________________________________________ */
.block-button-grid-slab {
	background: #002147 image-url("background-photo-texture2.png") no-repeat center center;
  	background-size: cover;
	padding: rem-calc(90) 0 rem-calc(130);
	@include on-dark;
	text-align: center;
	margin-bottom: rem-calc(40);

	
	h1, h2, h3, h4, h5, h6 {
		font-size: $font-size-large;
		text-transform: uppercase;
		// font-weight: bold;
	}
	a.arrow-link {
		text-decoration: underline;
		font-weight: normal;
	}
	.block-button-grid {
		margin-top: rem-calc(50);
	}
}


/* Bond Grid
/* _______________________________________________________________ */
.bond-grid {
	text-align: center;
	// padding: rem-calc(65) 0 rem-calc(45);
	margin-bottom: rem-calc(20);
	h1, h2, h3, h4, h5, h6 {
		@include dotted-lines(below, true);
		margin-bottom: rem-calc(30);
		&:after {
			margin-top: 0;
		}
	}
	ul {
		@extend .strip-list;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		li {
			width: 31%;
			margin: 0 rem-calc(10);
			@media #{$medium-down} {
				width: 46%;
			}
			@media #{$small-down} {
				width: 100%;
				margin: 0;
			}
			&:last-child {
				a.block-button {
					@media #{$small-down} {
						margin-bottom: 0;
					}
				}
			}
			a.block-button {
				display: block;
			}
		}
	}
}


/* Masonry Grid
/* _______________________________________________________________ */
.masonry-grid {
	@include dotted-lines(below);
	margin-bottom: rem-calc(40);
	ul {
		@extend .strip-list;
		column-count: 3;
		margin-bottom: rem-calc(20);
		@media #{$medium-down} {
			column-count: 2;
		}
		@media #{$small-down} {
			column-count: 1;
		}
		li {
			display: block;
			-webkit-column-break-inside: avoid;
			page-break-inside: avoid;
			break-inside: avoid;
			.masonry-item {
				margin-left: 3px; // prevent clipping of line-circle border
			}
		}
	}
}