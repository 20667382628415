/* -------------------------------------------------------------- */
/*  Atoms - Buttons
/* -------------------------------------------------------------- */

/* Buttons
/* _______________________________________________________________ */
a.button {
	font-family: $font-primary;
	border-radius: rem-calc(16);
	padding: rem-calc(10) rem-calc(32);
	font-size: $font-size-default;
	background-color: $color-accent-primary;
	&:hover,
	&:active {
		text-decoration: none;
		background-color: lighten($color-accent-primary, 15%);
	}
	&:focus {
		background-color: darken($color-accent-primary, 10%);
		text-decoration: none;
	}
	&.button-arrow {
		&:before {
			content: $fa-var-long-arrow-right;
			@extend .fa;
			margin-right: rem-calc(7);
		}
	}
	&.button-highlight {
		background-color: $color-accent-quaternary;
		&:hover,
		&:active {
			background-color: lighten($color-accent-quaternary, 10%);
		}
		&:focus {
			background-color: darken($color-accent-quaternary, 10%);
		}
	}
	&.block-button {
		background: $color-shade-light;
		padding: rem-calc(30) rem-calc(25);
		border-radius: 0;
		text-align: left;
		&:hover {
			background: lighten($color-shade-light, 5%);
		}
		.superhead {
			font-style: italic;
			font-family: $font-tertiary;
			color: $color-text;
			font-size: $font-size-large;
			// margin-bottom: rem-calc(10);
		}
		.headline {
			color: $color-accent-primary;
			text-transform: uppercase;
			font-size: $font-size-larger;
		}
		.blurb {
			color: $color-shade-darker;
		}
		&.dark {
			background: $color-accent-primary;
			&:hover {
				background: lighten($color-accent-primary, 10%);
			}
			.superhead,
			.headline,
			.blurb {
				color: #fff;
			}
		}
	}
	&.tweet-link {
		text-transform: none;
		font-weight: bold;
	}
}

