/* -------------------------------------------------------------- */
/*    Organisms - News
/* -------------------------------------------------------------- */


/* News Articles Index (list of articles on news homepage)
/* _______________________________________________________________ */
.news-articles-index {
	ul.articles-list {
		@extend .strip-list;
		column-count: 2;
		@media #{$medium-down} {
			column-count: 1;
		}
		& > li {
			display: block;
			-webkit-column-break-inside: avoid;
			page-break-inside: avoid;
			break-inside: avoid;
			.news-category-listing {
				padding-bottom: rem-calc(20); // keep dot with the column it belongs in (plus spacing for easier reading)
				margin-left: 3px; // prevent clipping of line-circle border
			}
		}
	}
}


/* News Featured Article (featured/hero story on news homepage)
/* _______________________________________________________________ */
.news-featured-article {
	margin-bottom: rem-calc(20);
	figure {
		margin-bottom: rem-calc(20);
	}
	h1, h2, h3, h4, h5, h6 {
		&.article-title {
			font-size: $font-size-largest;
			@media #{$small-down} {
				font-size: $font-size-larger;
			}
		}
		&.article-subtitle {
			font-size: $font-size-default;
			font-weight: bold;
		}
	}
	ul.meta-list {
		margin-top: rem-calc(10);
		li {
			display: block;
			border: 0;
			margin: 0 0 rem-calc(5) 0;
			a.story-label {
				margin-bottom: rem-calc(10);
				font-size: rem-calc(12);
			}
		}
	}
}