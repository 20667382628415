/* -------------------------------------------------------------- */
/*	Atoms - Text
/* -------------------------------------------------------------- */



/* Blockquote
/* _______________________________________________________________ */
blockquote {
	$quote-icon-size: rem-calc(48);
	margin: 0 0 0 0;
	padding: 0;
	color: $color-text;
	border: none;
	text-align: center;
	@include quote-icon();
	margin-top: $quote-icon-size;
	margin-bottom: rem-calc(20);
	p {
		color: inherit;
		font-size: $font-size-larger;
		margin-bottom: 0;
	}
	cite {
		color: inherit;
		font-style: normal;
		font-family: $font-secondary;
		font-size: rem-calc(16);
		margin-top: rem-calc(3);
	}
	&:before {
		width: rem-calc(220);
		height: $quote-icon-size/2;
		top: -$quote-icon-size/1.7;
	}
}


/* Headings
/* _______________________________________________________________ */

// foundation overrides
h1, h2, h3, h4, h5, h6 {
	font-family: $font-primary;
	color: $color-text;
}

h1,
.alpha {
}


h2,
.beta {
	&.tight-heading {
		line-height: rem-calc(30);
	}
}

h3,
.gamma {
}

h4,
.delta {
}

h5, .epsilon {
}

h6, .zeta {
}

.alpha { font-size: $h1-font-size - $h1-font-reduction; }
.beta { font-size: $h2-font-size - $h2-font-reduction; }
.gamma { font-size: $h3-font-size - $h3-font-reduction; }
.delta { font-size: $h4-font-size - $h4-font-reduction; }
.epsilon { font-size: $h5-font-size - $h5-font-reduction; }
.zeta { font-size: $h6-font-size - $h6-font-reduction; }


/* Subheadings
/* _______________________________________________________________ */
.subheading {
	font-family: $font-secondary;
	font-weight: normal;
}


/* Horizontal Rule
/* _______________________________________________________________ */
hr {
	border: 0;
	margin: 0;
	height: rem-calc(48);
	@include dotted-lines(below);
	&:after {
		margin-top: 0;
	}
}


/* Inline Elements
/* _______________________________________________________________ */
a {
	color: $color-link;
	outline: 0;
	transition: $transition-time;
	text-decoration: none;
	&:hover,
	&:focus {
		color: $color-link-hover;
		text-decoration: underline;
	}
}

/* Paragraph
/* _______________________________________________________________ */


/* Paragraph with Dropcap
/* _______________________________________________________________ */
p.dropcap:first-child:first-letter {
	float: left;
	font-size: rem-calc(80);
	line-height: rem-calc(60);
	font-family: $font-tertiary;
	font-style: italic;
	padding: rem-calc(10) rem-calc(15) 0 0;
}


/* Preformatted Text
/* _______________________________________________________________ */



/* Time
/* _______________________________________________________________ */
