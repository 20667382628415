/* -------------------------------------------------------------- */
/*    Molecules - Forms
/* -------------------------------------------------------------- */


/* Search
/* _______________________________________________________________ */
form.search {
	fieldset {
		border: 0;
		margin: 0;
		padding: 0;
	}
	button {
		font-size: $font-size-small;
		margin-left: rem-calc(10);
		@media #{$small-only} {
			font-size: 0;
			padding-right: rem-calc(15);
			padding-left: rem-calc(15);
			&:before {
				@include fa-icon;
				content: $fa-var-search;
				font-size: 14px;
			}
		}
	}
}


/* Search Archives
/* _______________________________________________________________ */
form.search-archives {
	input[type="search"],
	input[type="text"] {
		margin-bottom: rem-calc(10);
	}
	label {
		display: none;
	}
	select {
		margin: 0;
	}
	.button-wrapper {
		padding-left: rem-calc(10);
	}
	button {
		display: block;
		width: 100%;
		margin: 0;
		font-size: $font-size-small;
		font-size: 0;
		padding: rem-calc(11) rem-calc(10);
		border-radius: 0;
		&:before {
			@extend .fa;
			content: $fa-var-search;
			font-size: 14px;
		}
	}
}