/* -------------------------------------------------------------- */
/*    Atoms - Global
/* -------------------------------------------------------------- */


/* Animations
/* _______________________________________________________________ */
.animate-fade {
	transition: opacity $transition-time ease-out;
	&:hover {
		opacity: 0;
	}
}

.animate-move {
	> .demo-shape {
		transition: all $transition-time-slow ease-in-out;
	}
}


/* Colors
/* _______________________________________________________________ */
ul.sg-colors li {
	background-color: #fff;
}

// primaries
.bg-primary {
	background-color: $color-primary;
}
.bg-secondary {
	background-color: $color-secondary;
}
.bg-tertiary {
	background-color: $color-tertiary;
}
.bg-quaternary {
	background-color: $color-quaternary;
}
.bg-quinary {
	background-color: $color-quinary;
}
.bg-senary {
	background-color: $color-senary;
}
.bg-septenary {
	background-color: $color-septenary;
}
.bg-octonary {
	background-color: $color-octonary;
}
.bg-nonary {
	background-color: $color-nonary;
}
.bg-denary {
	background-color: $color-denary;
}

// accents
.bg-accent-primary {
	background-color: $color-accent-primary;
}
.bg-accent-secondary {
	background-color: $color-accent-secondary;
}
.bg-accent-tertiary {
	background-color: $color-accent-tertiary;
}
.bg-accent-quaternary {
	background-color: $color-accent-quaternary;
}
.bg-accent-quinary {
	background-color: $color-accent-quinary;
}
.bg-accent-senary {
	background-color: $color-accent-senary;
}
.bg-accent-septenary {
	background-color: $color-accent-septenary;
}
.bg-accent-octonary {
	background-color: $color-accent-octonary;
}
.bg-accent-nonary {
	background-color: $color-accent-nonary;
}
.bg-accent-denary {
	background-color: $color-accent-denary;
}

// grays & shades
.bg-shade-lightest {
	background-color: $color-shade-lightest;
}
.bg-shade-lighter {
	background-color: $color-shade-lighter;
}
.bg-shade-light {
	background-color: $color-shade-light;
}
.bg-shade {
	background-color: $color-shade;
}
.bg-shade-dark {
	background-color: $color-shade-dark;
}
.bg-shade-darker {
	background-color: $color-shade-darker;
}
.bg-shade-darkest {
	background-color: $color-shade-darkest;
}



/* Fonts
/* _______________________________________________________________ */

// font families
.font-primary {
	font-family: $font-primary
}
.font-secondary {
	font-family: $font-secondary;
}
.font-tertiary {
	font-family: $font-tertiary;
}

// font sizes
.font-size-smallest {
	font-size: $font-size-smallest;
}
.font-size-smaller {
	font-size: $font-size-smaller;
}
.font-size-small {
	font-size: $font-size-small;
}
.font-size-default {
	font-size: $font-size-default;
}
.font-size-large {
	font-size: $font-size-large;
}
.font-size-larger {
	font-size: $font-size-larger;
}
.font-size-largest {
	font-size: $font-size-largest;
}


/* Visibility
/* _______________________________________________________________ */
// using the pre-defined set by foundaton



 body {
	background: $color-bg;
	font: 100%/1.5 $font-primary;
	-webkit-text-size-adjust: 100%;
	color: $color-text;
 }

 /* Clearfix */
 .clearfix {
	*zoom: 1;
 }
 .clearfix:before, .cf:after {
	content: " "; /* 1 */
	display: table; /* 2 */
 }

 .clearfix:after {
	clear: both;
 }

 .space-below {
	margin-bottom: $space;
	&.more {
	  margin-bottom: $space-double;
	}
	&.lots {
	  margin-bottom: 4*$space;
	}
 }

 .space-above {
	margin-top: $space;
	&.more {
	  margin-top: $space-double;
	}
	&.lots {
	  margin-top: 4*$space;
	}
 }

 /* Completely remove from the flow but leave available to screen readers. */
 .show-for-screenreaders {
	position: absolute !important;
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
 }

 .text-replace {
	display: inline-block;
	font-size: 0;
 }

 // for pseudoelement overlays
 %pseudo-cover {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	content: '';
	display: block;
 }

 .background-underlay {
	@extend %pseudo-cover;
	z-index: -1;
	background-size: cover;
	background-position: 0 100%;
	background-repeat: no-repeat;
 }

