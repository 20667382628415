/* -------------------------------------------------------------- */
/*    Molecules - Blocks
/* -------------------------------------------------------------- */


/* Arc Block
/* _______________________________________________________________ */
.arc-block {
	display: inline-block;
	padding: rem-calc(40) rem-calc(50) rem-calc(40) rem-calc(65);
	position: relative;
	overflow: hidden;
	@media #{$small-down} {
		// handle this when included by organisms
		// background: $color-accent-tertiary;
		// padding: rem-calc(30);
	}
	&:not(.invert) {
		@include on-dark;
	}
	h1, h2, h3, h4, h5, h6,
	p {
		margin-top: 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.arc-content-wrapper {
		position: relative;
		z-index: 2;
		.blurb-text {
			font-size: $font-size-small;
		}
		.blurb-subtext {
			font-family: $font-secondary;
			font-size: $font-size-small;
			text-transform: uppercase;
		}
	}
	.shape-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		right: auto;
		z-index: 1;
		@media #{$small-down} {
			// display: none;
		}
		path#shape-wave {
			display: none; // some patterns will turn this back on to show the wave texture.
		}
		path#shape-color {
			fill: $color-accent-tertiary;
		}
		svg {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	&.curve-right {
		.shape-wrapper {
			right: 0;
			left: auto;
			svg {
				left: auto;
				right: 0;
			}
		}
	}
}


/* Address Block
/* _______________________________________________________________ */
.address-block {
	margin-bottom: rem-calc(15);
	h1, h2, h3, h4, h5, h6 {
		font-size: $font-size-larger;
		font-weight: bold;
	}
	p {
		margin-bottom: rem-calc(5);
	}
}


/* Contact Block
/* _______________________________________________________________ */
.contact-block {
	margin-bottom: rem-calc(20);
	padding-top: rem-calc(10);
	@include quote-icon();
	&:before {
		width: 100%;
		height: 50%;
		// top: rem-calc(-48)/1.7;
	}
	h1, h2, h3, h4, h5, h6,
	p {
		margin-bottom: 0;
	}
	h1, h2, h3, h4, h5, h6 {
		font-weight: bold;
	}
	.button {
		display: block;
		margin-top: rem-calc(50);
		@extend .button-highlight;
	}
}


/* Campaign Blocks - defaults
/* _______________________________________________________________ */
.campaign-number-block,
.campaign-content-block {
	height: 1px;
	min-height: rem-calc(620);
	@include on-dark();
	display: flex;
	align-items: center;
	justify-content: center;
	@media #{$medium-down} {
		min-height: 0;
	}
	.inner {
		-ms-flex: 0 1 auto;
	}
}


/* Campaign Content Block
/* _______________________________________________________________ */
.campaign-content-block {
	background: $color-accent-primary image-url('wave-tile-dark.png');
	padding: rem-calc(20) rem-calc(60);
	@media #{$small-down} {
		padding: rem-calc(20);
	}
	ul.fact-list {
		@include block-grid(2);
	}
	// themes
	// medium should match the default settings
	&.theme-light {
		background-color: $color-accent-secondary;
		.single-tweet-promo .tweetbox {
			background-color: $color-accent-primary;
		}
		.quote-block .shape-wrapper {
			path#shape-color {
				fill: $color-accent-primary;
			}
		}
	}
	&.theme-medium {
		background-color: $color-accent-primary;
		.single-tweet-promo .tweetbox {
			background-color: $color-primary;
		}
		.quote-block .shape-wrapper {
			path#shape-color {
				fill: $color-primary;
			}
		}
	}
	&.theme-dark {
		background-color: $color-primary;
		.single-tweet-promo .tweetbox {
			background-color: $color-accent-secondary;
		}
	}
	.quote-block .shape-wrapper {
		path#shape-color {
			fill: $color-accent-secondary;
		}
	}
}


/* Campaign Number Block
/* _______________________________________________________________ */
.campaign-number-block {
	background: $color-accent-primary;
	text-align: center;
	padding: 0 rem-calc(130);
	position: relative;
	overflow: hidden;
	// display: flex;
	// align-items: center;
	@media #{$small-down} {
		padding: rem-calc(20);
	}
	h1, h2, h3, h4, h5, h6 {
		margin-top: 0;
	}
	.background-image {
		position: absolute;
		bottom: 0;
		left: -40%;
		opacity: 0.2;
		img {
			width: auto;
			max-width: none;
		}
	}
	.inner {
		margin: 0 auto;
		z-index: 1;
		@media #{$medium-down} {
			padding: rem-calc(150) 0;
		}
	}
	// themes
	// medium should match the default settings
	&.theme-light {
		background-color: $color-accent-secondary;
	}
	&.theme-medium {
		background-color: $color-accent-primary;
	}
	&.theme-dark {
		background-color: $color-primary;
	}
}


/* Intro Block
/* _______________________________________________________________ */
.intro-block {
	@include dotted-lines(above-below);
	text-align: center;
	margin-bottom: rem-calc(20);
	& > :last-child {
		margin-bottom: 0;
	}
}


/* List Block
/* _______________________________________________________________ */
.list-block {
	ul {
		column-count: 2;
		@media #{$small-down} {
			column-count: 1;
		}
	}
}

/* Masonry Grid Item
/* _______________________________________________________________ */
.masonry-item {
	padding: rem-calc(10) 0;
	.inner {
		@include news-dot-lines();
		padding: 0 0 0 rem-calc(20);
	}
	// margin-top: rem-calc(10);
}


/* Person Detail Block
/* _______________________________________________________________ */
.person-detail-block {
	margin-bottom: rem-calc(20);
	p {
		// font-size: $font-size-small;
	}
	.name,
	.phone,
	.title {
		margin-bottom: 0;
	}
	figure {
		@media #{$small-down} {
			margin-bottom: rem-calc(20);
		}
	}
}


/* Quote Blocks
/* _______________________________________________________________ */
.quote-block {
	@extend .arc-block;
	padding: rem-calc(20);
	margin-bottom: rem-calc(20);
	@include on-dark();
	@media #{$small-down} {
		background-color: $color-accent-primary;
	}
	.shape-wrapper {
		path#shape-color {
			fill: $color-accent-primary;
		}
		@media #{$small-down} {
			path#shape-color {
				display: none;
			}
		}
	}
	figure {
		img {
		 	border-radius: 50%;
		}
	}
	blockquote {
		margin: 0;
		padding: 0;
		&:before {
			content: '“';
			background: none;
			margin: 0;
			width: 50px;
			height: 50px;
			top: -12px;
			left: -38px;
			color: #fff;
			font-family: $font-tertiary;
			font-style: italic;
			font-size: rem-calc(50);
		}
		text-align: left;
		p {
			font-weight: normal;
			font-size: $font-size-default;
			margin-bottom: rem-calc(14);
		}
		cite {
			font-family: $font-primary;
			font-size: $font-size-smaller;
			text-transform: uppercase;
			font-weight: bold;
			&:before {
				display: none;
			}
		}
	}
	&.curve-left {
		.arc-content-wrapper {
			padding-left: 10%;
		}
		.content {
			@include grid-column($columns: 8);
		}
		.headshot {
			@include grid-column($columns: 4);
		}
	}
	&.curve-right {
		.arc-content-wrapper {
			padding-right: 10%;
			@media #{$small-down} {
				padding-right: 0;
			}
		}
		.content {
			@include grid-column($columns: 8, $push: 4);
		}
		.headshot {
			@include grid-column($columns: 4, $pull: 8);
		}
	}
}