/* -------------------------------------------------------------- */
/*    Atoms - Lists
/* -------------------------------------------------------------- */



 /* Definition Lists
 /* _______________________________________________________________ */ 
dl {
	margin: 0;
	dt {
		margin: 0;
		&.no-definition {
			margin-bottom: 0.75rem;
		}
	}
}

dd {
	margin-left: 0;
}

/* Unordered, Ordered (set defaults)
 /* _______________________________________________________________ */ 
ol, ul {
	margin: 0;
	padding: 0;
	list-style: none;
	margin-bottom: rem-calc(20);
	counter-reset: brand-counter;
	li {
		text-indent: rem-calc(-16);
		margin-left: rem-calc(23);
		&:before {
			display: inline-block;
			color: $color-primary;
		}
	}
}

ul {
	li {
		&:before {
			// use the fontawesome small dot,
			// which is less ugly and intrusive than the html default bullet
			content: "\f111";
			font-family: 'FontAwesome';
			font-size: rem-calc(4);
			vertical-align: top;
			margin-left: rem-calc(16);
			margin-top: rem-calc(8);
		}
	}
}

ol {
	li {
		counter-increment: brand-counter;
		&:before {
			margin-left: .75rem;
			content: counter(brand-counter) ". ";
		}
	}
}


 /* Strip Unordered List 
 /* to use a list for layout instead of editorial/text/content
 /* _______________________________________________________________ */ 
ul.strip-list {
	margin: 0 0 0 0;
	&> li {
		text-indent: 0;
		margin-left: 0;
		&:before {
			display: none;
		}
	}
}

ul[class*="block-grid"] {
	@extend .strip-list;
}


 /* Loose UL Lists
 /* _______________________________________________________________ */ 
ul.loose-list {
	li {
		margin-bottom: rem-calc(10);
	}
}

 /* Inline UL Lists
 /* _______________________________________________________________ */ 
ul.inline {
	list-style: none;
	li {
		display: inline-block;
		text-indent: 0;
		margin-left: 0;
		margin-right: rem-calc(20);
		&:last-child {
			margin-right: 0;
		}
		&:before {
			display: none;
		}
	}
	&.spaced-more {
		li {
			margin-right: rem-calc(40);
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
