/* -------------------------------------------------------------- */
/*    Organisms - Global
/* -------------------------------------------------------------- */


/* Header
/* _______________________________________________________________ */
header.main-header {
	background: $color-primary image-url('wave-header.png') no-repeat top center;
	@media #{$medium-down} {
		background: $color-accent-primary;
	}
	@include on-dark;
	.header-top {
		background-color: $color-accent-primary;
		@media #{$medium-down} {
			background: $color-primary image-url('wave-header.png') no-repeat 70% 0;
		}
		a.toggle-menu {
			display: none;
			text-align: center;
			text-transform: uppercase;
			background: $color-accent-primary;
			border-bottom: rgba(#fff, 0.4) 1px solid;
			padding: rem-calc(10) 0;
			text-decoration: none;
			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
			}
			&:before {
				@extend .fa;
				content: $fa-var-bars;
				margin-right: rem-calc(5);
			}
			&.active {
				&:before {
					content: $fa-var-close;
				}
			}
			@media #{$medium-down} {
				display: block;
			}
		}
	}
	.header-primary {
		padding: rem-calc(30) 0;
		overflow: hidden;
		@media #{$medium-down} {
			text-align: center;
			display: none;
		}
		.logo,
		nav.primary-nav {
			float: left;
			@media #{$medium-down} {
				float: none;
			}
		}
		.logo {
			margin-right: rem-calc(70);
			position: relative;
			// glow behind logo:
			$glow-color: #003674;
			$glow-percentage: 70%;
			z-index: 1;
			@media #{$medium-down} {
				display: none;
			}
			&:before,
			&:after {
				display: block;
				content: '';
				position: absolute;
				// 							future thing to do:
				// 							auto-center this behind the logo
				//							so it moves with it better responsively
				top: -250px;
				left: -125px;
				width: 500px;
				height: 500px;
				background-image: radial-gradient(circle, $glow-color 0%, rgba($glow-color, 0.01) $glow-percentage);
				z-index: 1;
				opacity: 1;
				@include transition(opacity, 0.5s, ease-out);
			}
			&:after {
				background-image: radial-gradient(circle, lighten($glow-color, 1.5%) 0%, rgba($glow-color, 0.01) $glow-percentage);
				opacity: 0;
			}
			&:hover {
				&:before {
					opacity: 0;
				}
				&:after {
					opacity: 1;
				}
			}
			img {
				position: relative;
				z-index: 2;
			}
		}
		ul.nav-list.tools, nav.primary-nav {
			position: relative;
			z-index: 2;
		}
		nav.primary-nav {
			margin-top: rem-calc(16);
		}
		ul.nav-list.tools {
			display: none;
			margin: 0;
			@extend .inline;
			@media #{$medium-down} {
				display: inline-block;
			}
			li {
				margin: 0;
				form {
					input[type="text"] {
						// display: block;
					}
				}
				a.lancer-dashboard-toggle {
					border-left: #fff 1px solid;
				}
			}
		}
	}
}



/* Footer
/* _______________________________________________________________ */
footer.main-footer {
	background: $color-primary image-url('wave-footer.png') no-repeat top center;
	padding: rem-calc(70) 0 rem-calc(30) 0;
	@include on-dark;
	@media #{$small-down} {
		background-position: -300px 0;
	}
	p {
		font-size: $font-size-smaller;
	}
	a {
		text-decoration: underline;
		&:before,
		&:after {
			text-decoration: none;
			line-height: 0.6;
			overflow: hidden;
		}
		&.arrow-link {
			font-size: $font-size-smaller;
		}
	}
	.footer-top {
		.address {
			margin-bottom: rem-calc(20);
			p {
				margin-bottom: 0;
			}
		}
		h1, h2, h3, h4, h5, h6 {
			&.footer-column-header {
				font-size: $font-size-smaller;
				text-transform: uppercase;
			}
		}
		ul:not(.social-links) {
			@extend .strip-list;
			@extend .loose-list;
			li {
				font-size: $font-size-smaller;
				a {
					color: $color-accent-senary;
					&:hover {
						color: lighten($color-accent-senary, 10%);
					}
				}
			}
		}
		.location {
			img {
				margin-top: rem-calc(10);
			}
		}
		.campus {
			@media #{$small-down} {
				text-align: center;
				margin-top: rem-calc(30);
			}
			ul.campus-links {
				column-count: 2;
				margin-bottom: rem-calc(20);
				@media #{$small-down} {
					column-count: 1;
				}
				li {
					column-width: 50%;
				}
			}
		}
		.social {
			@media #{$medium-down} {
				text-align: center;
				margin-top: rem-calc(30);
			}
			a.social-hub {
				float: right;
				color: $color-accent-senary;
				text-decoration: none;
				text-transform: uppercase;
				&:hover {
					color: lighten($color-accent-senary, 10%);
				}
				&:before {
					@extend .fa;
					content: $fa-var-long-arrow-right;
					margin-right: rem-calc(3)
				}
			}
			ul.social-links {
				@extend .inline;
				li {
					margin-right: rem-calc(20);
					&:last-child {
						margin-right: 0;
					}
					a {
						$circle-size: rem-calc(50);
						display: inline-block;
						height: $circle-size;
						width: $circle-size;
						line-height: $circle-size;
						text-align: center;
						border: #fff 2px solid;
						border-radius: $circle-size/2;
						font-size: rem-calc(16);
						&:hover {
							background-color: rgba($color-accent-secondary, 0.5);
						}
					}
				}
			}
		}
	}
	.footer-bottom {
		$logo-height: 29px;
		text-transform: uppercase;
		text-align: center;
		@media #{$medium-down} {
			text-align: center;
		}
		hr {
			margin: rem-calc(30) 0;
			height: auto;
			border-top: rgba(#000, 0.5) 1px solid;
			&:after {
				display: none;
			}
		}
		p {
			margin-bottom: 0;
			line-height: $logo-height;
		}
		ul {
			// @extend .inline;
			@extend .strip-list;
			margin-bottom: 0;
			li {
				font-size: $font-size-smallest;
				line-height: $logo-height;
			}
		}
		.footer-logo {
			text-align: center;
			margin: 0 0 rem-calc(20) 0;
			.logo {
				img {
					width: 200px;
					height: $logo-height;
				}
			}
		}
		.columns {
			&:nth-child(2) {
				// text-align: center;
			}
			&:nth-child(3) {
				// text-align: right;
			}
		}
	}
}