/* -------------------------------------------------------------- */
/*    Molecules - Media
/* -------------------------------------------------------------- */


/* Figure with Caption
/* _______________________________________________________________ */
figure {
	margin-bottom: rem-calc(20);
	figcaption {
		// margin-bottom: rem-calc(20);
		background: $color-shade-lighter;
		padding: rem-calc(20);
		font-family: $font-secondary;
		font-size: $font-size-small;
        max-width:100%;
	}
}


/* Gallery Carousel
/* _______________________________________________________________ */
.gallery-carousel {
	margin-bottom: rem-calc(20);
	.slick-gallery-carousel {
		// position: relative;
		padding: 0 rem-calc(40);
		button {
			background: none;
			padding: 0;
			margin: 0;
			margin: auto;
			height: rem-calc(50);
			font-size: rem-calc(50);
			position: absolute;
			bottom: 0;
			top: 0;
			outline: 0;
			color: $color-primary;
			&.slick-next {
				right: 10px;
			}
			&.slick-prev {
				left: 10px;
			}
		}
		.slide {
			outline: 0;
			a {
				display: block;
				outline: 0;
				position: relative;
				margin: 0 rem-calc(10);
				&:hover {
					text-decoration: none;
					&:after {
						background: lighten($color-accent-quaternary, 10%);
					}
				}
				&:after {
					content: $fa-var-search-plus;
					@extend .fa;
					position: absolute;
					bottom: 0;
					right: 0;
					z-index: 999999;			// will probably need to adjust to work with lightbox level
					padding: rem-calc(5) rem-calc(7);
					background: $color-accent-quaternary;
					color: #fff;
					// font-size: $font-size-large;
					@include transition(background, $transition-time, ease-out);
				}
			}
		}
	}
}

.gallery-modal {
	background: transparent;
	border: none;
	box-shadow: none;
	border-radius: 0;
	padding: 0;
	width: 100%;
	max-width: $row-width;
    margin: 0 auto;
//	height: 0;
	text-align: center;
	cursor: zoom-out;
	img {
		// max-width: $row-width;
	}
}

.reveal-modal-bg.gallery-reveal-modal-bg {
	cursor: zoom-out;
}

/* Lightbox content and arrow styling */
.mfp-gallery {
    
    .mfp-container {
        display: flex;
        align-items: center;
        justify-content: center;
        > * {
            position: relative;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;
            margin: 0;
            width: auto;
    //        max-width: 90%;
        }
        
        .mfp-content {
            order: 2;
            max-width: 80%;
        }
        
        > button.mfp-arrow {
            width: 3rem;
            &:before, &:after {
                width: 100%;
                margin: 0;
                border: none;
            }
            
            &.mfp-arrow-left {
                order: 1;
                margin-right: 1rem;
            }
            
            &.mfp-arrow-right {
                order: 3;
                margin-left: 1rem;
            }
        }
    }
    
    button {
        &.mfp-arrow {
            background-color: transparent;
            &:active, &:hover, &:focus {
                background-color: transparent;
            }
            
            &:before, &:after {
                border: none;
            }
            
            &:before {
                font-family: 'FontAwesome';
                font-size: 5rem;
            }
            
            &.mfp-arrow-right {
                &:before {
                    content: '\f105';
                }
            }
            
            &.mfp-arrow-left {
                &:before {
                    content: '\f104';
                }
            }
        }
    }
}

/* Lightbox modal animation */
.mfp-slide-fade.mfp-bg {
    opacity: 0;
    transition: opacity 150ms ease-out;

    &.mfp-ready {
        opacity: 0.45;
    }
    
    &.mfp-removing {
        opacity: 0;
        transition: opacity 150ms ease-in-out;
    }
}
    
.mfp-slide-fade.mfp-wrap {
    .mfp-content {
        top: -1000px;
        opacity: 0;
        transition: top 300ms ease-out;
    }
    
    
    &.mfp-ready {
        .mfp-content {
            top: 0;
            opacity: 1;
        }
    }
    
    &.mfp-removing {
        .mfp-content {
            opacity: 0;
            transition: opacity 300ms ease-in-out;
            transition-delay: 100ms;
        }
    }
}

.mfp-fade.mfp-bg {
    opacity: 0;
    transition: opacity 150ms ease-out;

    &.mfp-ready {
        opacity: 0.45;
    }
    
    &.mfp-removing {
        opacity: 0;
        transition: opacity 150ms ease-in-out;
    }
}
    
.mfp-fade.mfp-wrap {
    .mfp-content {
        opacity: 0;
        transition: opacity 300ms ease-out;
    }
    
    
    &.mfp-ready {
        .mfp-content {
            opacity: 1;
        }
    }
    
    &.mfp-removing {
        .mfp-content {
            opacity: 0;
            transition: opacity 300ms ease-in-out;
            transition-delay: 100ms;
        }
    }
    
    
}


/* Hero Cutout Image
/* _______________________________________________________________ */
.hero-cutout-image {
	max-height: rem-calc(460);
	max-width: rem-calc(1600);
	overflow: hidden;
	margin: 0 auto;
	position: relative;
	&:after {
		display: inline-block;
		content: '';
		position: absolute;
		right: -1px;
		bottom: -1px;
		width: 238px;
		height: 200px;
		background: image-url('hero-mask.svg') no-repeat bottom right;
		background-size: contain;
		@media #{$large-down} {
			max-width: 20%;
		}
	}
}
