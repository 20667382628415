/* -------------------------------------------------------------- */
/*    Organisms - Full Page slides
/* -------------------------------------------------------------- */

$mobile-vert-section-padding: rem-calc(80);

/* Full Page Header Block
/* _______________________________________________________________ */
.full-page .section.header-block {
	background: $color-accent-secondary image-url('longwood-large-bg2.jpg') no-repeat top center;
	background-size: cover;
	display: flex;
	flex-direction: column;
	
	.row {
	}
	
	header.main-header {
		width: 100%;
	}
	
	.valign-wrap {
		flex-grow: 2;
		display: flex;
		min-height: 100%;
		
		align-items: center;
		justify-content: center;
		-ms-flex: 0 1 auto;
		
		.row {
			max-width: 100%;
		}
	}
	.content-wrapper {
		@include on-dark();
		text-align: center;
		margin-top: -4.375rem;
		@media #{$medium-down} {
			margin-top: 0;
			padding: $mobile-vert-section-padding 0;
		}
		h1, h2, h3, h4, h5, h6 {
			font-family: $font-secondary;
			font-size: rem-calc(80);
			@media #{$small-down} {
				font-size: rem-calc(50);
			}
		}
		a.full-page-scroll-down {
			display: inline-block;
			font-family: $font-secondary;
			text-transform: uppercase;
			@media #{$medium-down} {
				display: none;
			}
			&:hover,
			&:active,
			&:focus {
				text-decoration: none !important;
				color: $color-accent-quinary;
			}
			&:before,
			&:after {
				display: block;
			}
			&:before {
				content: '....';
				font-size: rem-calc(34);
				letter-spacing: 6px;
				font-family: $font-primary;
			}
			&:after {
				@extend .fa;
				content: $fa-var-angle-double-down;
				font-size: rem-calc(40);
			}
		}
		.block-title {
			display: none;
		}
	}
}

/* Full Page Navigation
/* _______________________________________________________________ */
.full-page-nav {
	position: absolute;
	z-index: 99999;
	left: 0;
	top: auto;
	bottom: 0;
	width: 100%;
	background: darken($color-shade-lighter, 5%);
	padding: rem-calc(20) 0;
	border-bottom: $color-shade 5px solid;
	@media #{$medium-down} {
		display: none;
	}
	&.active {
		top: 0;
		bottom: auto;
	}
	ul {
		@extend .inline;
		margin-bottom: 0;
		text-align: center;
		li {
			&.active a {
				&:after {
					$triangle-size: 20px;
					position: absolute;
					top: auto;
					bottom: -$triangle-size * 2;
					left: 0;
					right: 0;
					margin: auto;
					display: inline-block;
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: $triangle-size $triangle-size 0 $triangle-size;
					border-color: darken($color-shade-lighter, 5%) transparent transparent transparent;
				}
			}
			&:first-child,
			&:last-child {
				// display: none; // hide the intro and footer block from the nav
				a {
					font-size: 0;
					&:before {
						@extend .fa;
						font-size: rem-calc(16);
					}
				}
				&.active a {
					&:after {
						display: none;
					}
					&:before {
						color: $color-shade-dark;
					}
				}
			}
			// &:nth-last-child(2) {
			// 	margin-right: 0; // because the real last child is hidden
			// }
			&:first-child {
				a {
					&:before {
						content: $fa-var-long-arrow-up;
					}
				}
			}
			&:last-child {
				a {
					&:before {
						content: $fa-var-long-arrow-down;
					}
				}
			}
			a {
				position: relative;
				font-family: $font-secondary;
				font-size: $font-size-small;
				text-transform: uppercase;
				&:hover,
				&:active,
				&:focus {
					text-decoration: none;
				}
			}
		}
	}
}

// don't let nav overlay lancer dashboard
.move-left .full-page-nav {
	display: none;
}


/* Fullpage Section Defaults
/* _______________________________________________________________ */
.section[class*="full-page"] {
	overflow: hidden;
	position: relative;
}


/* Full Page Circle Photos
/* _______________________________________________________________ */
.full-page-circle-photos {
	background: $color-accent-secondary image-url('longwood-large-bg1.jpg') no-repeat top center;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	.section-header {
		position: relative;
		z-index: 1;
		margin: rem-calc(20) 0 0 0;
		.header {
			@extend .arc-block;
			text-align: center;
			@media #{$small-down} {
				padding: rem-calc(20);
				background: $color-accent-secondary;
			}
			.shape-wrapper {
				@media #{$small-down} {
					display: none;
				}
				path#shape-color {
					fill: $color-accent-secondary;
				}
			}
		}
	}
	.photos {
		position: relative;
		z-index: 2;
		margin: rem-calc(-40) 0 rem-calc(20) 0;
		@media #{$small-down} {
			margin-top: rem-calc(20);
		}
		figure {
			@media #{$small-down} {
				margin-bottom: rem-calc(20);
			}
			img {
				border-radius: 50%;
			}
		}
	}
}


/* Full Page Collage
/* _______________________________________________________________ */
.full-page-collage {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	.section-header {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		margin: rem-calc(20) 0 0 0;
		@media #{$medium-down} {
			position: static;
		}
		.header {
			@extend .arc-block;
			margin-left: rem-calc(20);
			@media #{$medium-down} {
				padding: rem-calc(20);
				margin-left: 0;
			}
			.shape-wrapper {
				@media #{$medium-down} {
					display: none;
				}
				path#shape-color {
					fill: #fff;
				}
			}
			.arc-content-wrapper {
				color: $color-text;
				p,
				blockquote cite,
				ul li:before {
					color: $color-text;
				}
				a:not(.button),
				h1, h2, h3, h4, h5, h6, {
					color: $color-accent-primary;
				}
			}
		}
	}
	.photos {
		@media #{$medium-down} {
			margin-bottom: rem-calc(30);
		}
		figure {
			@media #{$small-down} {
				// margin-bottom: rem-calc(30);
				// text-align: center;
			}
		}
		.large-photo {
			margin-top: rem-calc(30);
			@media #{$small-down} {
				margin-top: rem-calc(15);
			}
		}
		.small-photos {
			figure {
				&.small-photo-1 {
					margin-bottom: rem-calc(30);
					@media #{$small-down} {
						margin-bottom: rem-calc(15);
					}
				}
				&.small-photo-2 {
					margin-right: 30%;
					@media #{$small-down} {
						// margin-right: 0;
					}
				}
			}
		}
		.large-photo,
		.small-photos {
			// tighter column gutters in small view
			@media #{$small-down} {
				padding-right: rem-calc(7.5);
				padding-left: rem-calc(7.5);
			}
		}
	}
	&.theme-light {
		.section-header .header .arc-content-wrapper {
			a:not(.button),
			h1, h2, h3, h4, h5, h6, {
				color: $color-accent-secondary;
			}
		}
	}
	&.theme-medium {
		.section-header .header .arc-content-wrapper {
			a:not(.button),
			h1, h2, h3, h4, h5, h6, {
				color: $color-accent-primary;
			}
		}
	}
	&.theme-dark {
		.section-header .header .arc-content-wrapper {
			a:not(.button),
			h1, h2, h3, h4, h5, h6, {
				color: $color-primary;
			}
		}
	}
	&.theme-accent {
		.section-header .header .arc-content-wrapper {
			a:not(.button),
			h1, h2, h3, h4, h5, h6, {
				color: $color-accent-quaternary;
			}
		}
	}
}


/* Full Page Curve
/* _______________________________________________________________ */
.full-page-curve {
	background: $color-accent-secondary image-url('longwood-large-bg1.jpg') no-repeat top center;
	background-size: cover;
	padding-top: rem-calc(100);
	@media #{$medium-down} {
		padding-top: $mobile-vert-section-padding;
		// padding-bottom: rem-calc(500); // keep the bg photo visible under the curve
		padding-bottom: $mobile-vert-section-padding;
	}
	@media #{$small-down} {
		// padding-top: rem-calc(10);
	}
	circle#circle-color {
		fill: $color-accent-primary;
	}
	.circle-wrapper {
		position: absolute;
		left: -2500px;
		bottom: 0; // will be adjusted by js to "wrap" content
		@media #{$medium-down} {
			// when fullpage isn't running, fill the whole block with the circle
			// to fake "fill with bg pattern"
			left: -2000px;
		}
	}
	.content {
		@include on-dark();
		figure {
			@media #{$small-down} {
				margin-top: rem-calc(20);
				margin-bottom: rem-calc(20);
			}
		}
		@media #{$medium-down} {
			padding: 0 rem-calc(30);
		}
		@media #{$small-down} {
			padding: 0 rem-calc(10);
		}
	}
	&.theme-light {
		circle#circle-color {
			fill: $color-accent-secondary;
		}
	}
	&.theme-medium {
		circle#circle-color {
			fill: $color-accent-primary;
		}
	}
	&.theme-dark {
		circle#circle-color {
			fill: $color-primary;
		}
	}
}


/* Full Page Two Photo
/* _______________________________________________________________ */
.full-page-two-photo {
	background: $color-accent-secondary image-url('longwood-large-bg1.jpg') no-repeat top center;
	background-size: cover;
	padding-top: rem-calc(100);
	display: flex;
	align-items: center;
	justify-content: center;
	@media #{$medium-down} {
		padding-top: rem-calc(30);
	}
	.section-header {
		padding-bottom: rem-calc(40);
		text-align: center;
		@include on-dark();
	}
	.content {
		@extend .arc-block;
		@extend .curve-right;
		padding: rem-calc(30) rem-calc(40) rem-calc(30) rem-calc(30);
		margin: rem-calc(-120) 0 rem-calc(20) 0;
		width: 70%;
		@media #{$medium-down} {
			padding: rem-calc(20);
			margin-top: 0;
			width: 100%;
			background: $color-accent-primary;
		}
		.shape-wrapper {
			@media #{$medium-down} {
				display: none;
			}
			path#shape-color {
				fill: $color-accent-primary;
			}
		}
	}
	&.theme-light {
		.content .shape-wrapper path#shape-color {
			fill: $color-accent-secondary;
		}
		@media #{$medium-down} {
			.content {
				background: $color-accent-secondary;
			}
		}
	}
	&.theme-medium {
		.content .shape-wrapper path#shape-color {
			fill: $color-accent-primary;
		}
		@media #{$medium-down} {
			.content {
				background: $color-accent-primary;
			}
		}
	}
	&.theme-dark {
		.content .shape-wrapper path#shape-color {
			fill: $color-primary;
		}
		@media #{$medium-down} {
			.content {
				background: $color-primary;
			}
		}
	}
	&.theme-accent {
		.content .shape-wrapper path#shape-color {
			fill: $color-accent-quaternary;
		}
		@media #{$medium-down} {
			.content {
				background: $color-accent-quaternary;
			}
		}
	}
}


/* Full Page Footer Block
/* _______________________________________________________________ */
.full-page .section.footer-block {
	display: flex;
	flex-direction: column;
	.wrapper {
		flex: 1;
		display: flex;
		align-items: center;
		@media #{$medium-down} {
			padding: rem-calc(40) 0;
		}
		.block-title {
			display: none;
		}
	}
}